import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { TooltipIconButton } from "@wa/werkstoff-core";
import { useFormik } from "formik";
import { gql } from "graphql.macro";
import { useSnackbar } from "material-ui-snackbar-provider";
import { ChevronDown, ChevronUp, Plus } from "mdi-material-ui";
import React, { useCallback, useState } from "react";
import ActionBar from "../../components/ActionBar";
import {
  CreatePackingMaterialDocument,
  GetPackingMaterialsDocument,
  PackingMaterialUnit,
  UpdatePackingMaterialDocument,
  RemovePackingMaterialDocument,
  UpdatePackingMaterialOrderDocument,
} from "../../generated/graphql";
import PackingMaterialForm, {
  PackingMaterialFormValue,
} from "./components/PackingMaterialForm";
import { getUnit } from "./units";

gql`
  mutation CreatePackingMaterial($packingMaterial: PackingMaterialInput!) {
    createPackingMaterial(packingMaterial: $packingMaterial) {
      id
      name
      price
      unit
      order
    }
  }
`;

gql`
  mutation UpdatePackingMaterial(
    $id: ID!
    $packingMaterial: PackingMaterialInput!
  ) {
    updatePackingMaterial(id: $id, packingMaterial: $packingMaterial) {
      id
      name
      price
      unit
      order
    }
  }
`;

gql`
  mutation RemovePackingMaterial($id: ID!) {
    removePackingMaterial(id: $id) {
      id
    }
  }
`;

gql`
  mutation UpdatePackingMaterialOrder($order: [ID!]!) {
    updatePackingMaterialOrder(order: $order) {
      id
      name
      price
      unit
      order
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: "pointer",
    },
    placeholder: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
    removeDialogButton: {
      color: theme.palette.error.dark,
      marginRight: "auto",
    },
    moreItems: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      "& > .MuiFormControlLabel-label": {
        ...theme.typography.body2,
      },
    },
    table: {
      "& td:first-child": {
        width: 50,
      },
    },
    tableIconButton: {
      margin: "-6px 0",
      padding: theme.spacing(0.5),
    },
  });

function RoomsContainer({ classes }: WithStyles<typeof styles>) {
  const snackbar = useSnackbar();

  const { data } = useQuery(GetPackingMaterialsDocument);

  const [createPackingMaterial] = useMutation(CreatePackingMaterialDocument, {
    refetchQueries: [{ query: GetPackingMaterialsDocument }],
  });
  const [addAnotherItem, setAddAnotherItem] = useState(false);
  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const handleCreatePackingMaterial = useCallback(
    async (value: PackingMaterialFormValue) => {
      try {
        await createPackingMaterial({
          variables: {
            packingMaterial: {
              name: value.name,
              price: value.price ?? 0,
              unit: value.unit,
            },
          },
        });
        snackbar.showMessage(
          `Das Packmaterial "${value.name}" wurde hinzugefügt.`
        );
      } catch (e) {
        snackbar.showMessage(
          "Das Packmaterial konnte nicht hinzugefügt werden."
        );
        console.error("Could not create packing material", e);
      }
    },
    [snackbar, createPackingMaterial]
  );
  const formikNewItem = useFormik<PackingMaterialFormValue>({
    initialValues: {
      name: "",
      unit: PackingMaterialUnit.Piece,
    },
    onSubmit: async (value) => {
      await handleCreatePackingMaterial(value);
      if (addAnotherItem) {
        formikNewItem.resetForm();
      } else {
        setShowAddItemDialog(false);
      }
    },
  });

  const [updatePackingMaterial] = useMutation(UpdatePackingMaterialDocument, {
    refetchQueries: [{ query: GetPackingMaterialsDocument }],
  });
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const [showEditItemDialog, setShowEditItemDialog] = useState(false);
  const formikEditItem = useFormik<PackingMaterialFormValue & { id?: string }>({
    initialValues: { name: "", unit: PackingMaterialUnit.Piece },
    onSubmit: async (value) => {
      try {
        await updatePackingMaterial({
          variables: {
            id: editItemId!,
            packingMaterial: {
              name: value.name,
              price: value.price ?? 0,
              unit: value.unit,
            },
          },
        });
        setShowEditItemDialog(false);
        setEditItemId(null);
        snackbar.showMessage(
          `Das Packmaterial "${value.name}" wurde aktualisiert.`
        );
      } catch (e) {
        snackbar.showMessage(
          "Das Packmaterial konnte nicht aktualisiert werden."
        );
      }
    },
  });

  const handleShowItem = useCallback(
    (e) => {
      const id = e.currentTarget.dataset.itemId;
      console.log({ id });
      setEditItemId(id);
      setShowEditItemDialog(true);
      formikEditItem.resetForm({
        values: data?.packingMaterials.find((m) => m.id === id),
      });
    },
    [formikEditItem, data]
  );

  const [removePackingMaterial] = useMutation(RemovePackingMaterialDocument, {
    refetchQueries: [{ query: GetPackingMaterialsDocument }],
  });
  const handleDeletePackingMaterial = useCallback(
    async (id: string) => {
      const material = data?.packingMaterials.find((m) => m.id === id);
      if (!material) return;
      try {
        await removePackingMaterial({ variables: { id } });
        setShowEditItemDialog(false);
        setEditItemId(null);
        snackbar.showMessage(
          `Das Packmaterial "${material.name}" wurde entfernt.`,
          "Rückgängig",
          () => handleCreatePackingMaterial(material)
        );
      } catch (e) {
        snackbar.showMessage("Das Packmaterial konnte nicht entfernt werden.");
      }
    },
    [data, snackbar, handleCreatePackingMaterial, removePackingMaterial]
  );

  const [updatePackingMaterialOrder] = useMutation(
    UpdatePackingMaterialOrderDocument,
    {
      refetchQueries: [{ query: GetPackingMaterialsDocument }],
    }
  );
  const handleMoveUp = useCallback(
    async (e) => {
      e.stopPropagation();
      const id = e.currentTarget.dataset.itemId;
      const ids =
        data?.packingMaterials.map((packingMaterial) => packingMaterial.id) ??
        [];
      const index = ids.indexOf(id);
      ids[index] = ids[index - 1];
      ids[index - 1] = id;
      try {
        await updatePackingMaterialOrder({ variables: { order: ids } });
      } catch (e) {
        snackbar.showMessage(
          "Die Reihenfolge konnte nicht aktualisiert werden."
        );
        console.error(e);
      }
    },
    [data, updatePackingMaterialOrder, snackbar]
  );
  const handleMoveDown = useCallback(
    async (e) => {
      e.stopPropagation();
      const id = e.currentTarget.dataset.itemId;
      const ids =
        data?.packingMaterials.map((packingMaterial) => packingMaterial.id) ??
        [];
      const index = ids.indexOf(id);
      ids[index] = ids[index + 1];
      ids[index + 1] = id;
      try {
        await updatePackingMaterialOrder({ variables: { order: ids } });
      } catch (e) {
        snackbar.showMessage(
          "Die Reihenfolge konnte nicht aktualisiert werden."
        );
        console.error(e);
      }
    },
    [data, updatePackingMaterialOrder, snackbar]
  );

  return (
    <>
      <ActionBar title="Packmaterial">
        <TooltipIconButton
          tooltip="Packmaterial hinzufügen"
          onClick={() => {
            formikNewItem.resetForm();
            setShowAddItemDialog(true);
          }}
        >
          <Plus />
        </TooltipIconButton>
      </ActionBar>
      <Paper>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nr.</TableCell>
              <TableCell>Bezeichnung</TableCell>
              <TableCell>Preis</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.packingMaterials.map((packingMaterial, i) => (
              <TableRow
                key={packingMaterial.id}
                onClick={handleShowItem}
                hover
                data-item-id={packingMaterial.id}
                className={classes.tableRow}
              >
                <TableCell align="right">{i + 1}</TableCell>
                <TableCell>{packingMaterial.name}</TableCell>
                <TableCell>
                  {packingMaterial.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  €/
                  {getUnit(packingMaterial.unit)}
                </TableCell>
                <TableCell align="right">
                  <TooltipIconButton
                    tooltip="Nach oben verschieben"
                    className={classes.tableIconButton}
                    disabled={i === 0}
                    onClick={handleMoveUp}
                    data-item-id={packingMaterial.id}
                  >
                    <ChevronUp />
                  </TooltipIconButton>
                  <TooltipIconButton
                    tooltip="Nach unten verschieben"
                    className={classes.tableIconButton}
                    disabled={i === data.packingMaterials.length - 1}
                    onClick={handleMoveDown}
                    data-item-id={packingMaterial.id}
                  >
                    <ChevronDown />
                  </TooltipIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Typography variant="caption">
        Die Reihenfolge des Packmaterials wird in der App und für die
        Platzhalter in den Vorlagen genutzt. Änderungen erfordern ggf. eine
        Anpassung der Vorlagen.
      </Typography>
      <Dialog
        open={showAddItemDialog}
        onClose={() => setShowAddItemDialog(false)}
        disableBackdropClick
        fullWidth
      >
        <DialogTitle>Packmaterial hinzufügen</DialogTitle>
        <DialogContent>
          <PackingMaterialForm formikConfig={formikNewItem} />
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={addAnotherItem}
                onChange={(e) => setAddAnotherItem(e.target.checked)}
                name="addAnotherItem"
                color="primary"
              />
            }
            label="Weiteres Packmaterial hinzufügen"
            className={classes.moreItems}
          />
          <Button onClick={() => setShowAddItemDialog(false)}>Abbrechen</Button>
          <Button color="primary" onClick={formikNewItem.submitForm}>
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showEditItemDialog}
        onClose={() => setShowEditItemDialog(false)}
        disableBackdropClick
        fullWidth
      >
        <DialogTitle>Packmaterial bearbeiten</DialogTitle>
        <DialogContent>
          <PackingMaterialForm formikConfig={formikEditItem} />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.removeDialogButton}
            onClick={() => handleDeletePackingMaterial(editItemId!)}
          >
            Entfernen
          </Button>
          <Button onClick={() => setShowEditItemDialog(false)}>
            Abbrechen
          </Button>
          <Button color="primary" onClick={formikEditItem.submitForm}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(RoomsContainer);
