import { useEffect, useMemo, useState } from "react";
import { useMutableSearchParam } from "./useSearchParams";

/**
 * Handle dialog state and avoid flickering of empty dialogs while closing by
 * keeping the data even if the dialog is closed.
 * @param initialData Initial data
 * @returns Dialog state
 */
export default function useDialogOpen<T>(initialData?: T) {
  const [open, setOpen] = useState(initialData != null);
  const [data, setData] = useState(initialData);
  return useMemo(
    () => ({
      open,
      data,
      handleClose: () => setOpen(false),
      handleOpen: (data: T) => {
        setData(data);
        setOpen(true);
      },
    }),
    [open, data]
  );
}

/**
 * Handle dialog state of dialogs that are controlled by a search param and
 * avoid flickering of empty dialogs while closing by keeping the data even
 * if the dialog is closed.
 * @param key Search param name
 * @returns Dialog state
 */
export function useSearchParamDialogOpen(key: string) {
  const [value, setValue] = useMutableSearchParam(key);
  const dialog = useDialogOpen<string>(value);
  useEffect(() => {
    if (value != null) {
      dialog.handleOpen(value);
    } else {
      dialog.handleClose();
    }
  }, [value, dialog]);
  return useMemo(
    () => ({
      open: dialog.open,
      data: dialog.data,
      handleClose: () => setValue(null),
      handleOpen: (data: string) => setValue(data),
    }),
    [dialog.data, dialog.open, setValue]
  );
}
