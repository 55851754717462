import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContentText,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  Theme,
  DialogProps,
} from "@material-ui/core";
import JWT from "jwt-client";
import { getAccessToken } from "../api";
import { useSnackbar } from "material-ui-snackbar-provider";

interface Props extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    code: {
      width: 250,
      margin: `${theme.spacing(3)}px auto 0`,
      display: "block",
    },
    dialogRoot: {
      background: theme.palette.primary.main,
    },
  });

function AuthenticationDialog({
  classes,
  onClose,
  ...other
}: Props & WithStyles<typeof styles>) {
  const snackbar = useSnackbar();
  const [accessKey, setAccessKey] = React.useState<string | null>(null);
  const [authenticationError, setAuthenticationError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleAuthentication = async () => {
    setLoading(true);
    try {
      const { accessToken } = await getAccessToken(accessKey as string);
      JWT.keep(JWT.read(accessToken));
      onClose();
    } catch (e) {
      if (e.message === "Wrong password") {
        setAuthenticationError(true);
      }
      snackbar.showMessage("Die Anmeldung ist fehlgeschlagen.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      hideBackdrop
      className={classes.dialogRoot}
      {...other}
    >
      <DialogTitle>Anmeldung</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary" variant="body2">
          Bitte melden Sie sich an, um auf das Backend der Umzugs-App
          zuzugreifen.
        </DialogContentText>
        <TextField
          className={classes.code}
          label="Passwort"
          margin="dense"
          variant="filled"
          type="password"
          value={accessKey ?? ""}
          onChange={(e) => {
            setAccessKey(e.target.value);
            if (authenticationError) {
              setAuthenticationError(false);
            }
          }}
          autoFocus
          error={authenticationError}
          helperText={authenticationError ? "Falsches Passwort" : undefined}
          InputLabelProps={{ shrink: true }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleAuthentication();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={handleAuthentication}
          disabled={accessKey === "" || loading}
        >
          Anmelden
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(AuthenticationDialog);
