import React from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
  createStyles,
  AppBarProps,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

const styles = createStyles({
  root: {
    overflow: "hidden",
    paddingTop: "var(--safe-area-inset-top)",
  },
  flex: {
    flex: 1,
  },
  drawerButton: {
    marginLeft: -12,
    marginRight: 12,
  },
  rightButton: {
    marginLeft: 12,
    marginRight: -12,
  },
  title: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    flex: 1,
    whiteSpace: "nowrap",
  },
  toolbar: {
    "& > button:last-child": {
      marginRight: -12,
    },
  },
});

interface Props extends AppBarProps {
  title?: string;
  onButtonClick?: React.MouseEventHandler;
  onRightButtonClick?: React.MouseEventHandler;
  children?: React.ReactNode;
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
}

function AppBar({
  classes,
  className,
  title,
  onButtonClick,
  onRightButtonClick,
  children,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  ...other
}: Props & WithStyles<typeof styles>) {
  return (
    <MuiAppBar
      position="static"
      className={clsx(classes.root, className)}
      {...other}
    >
      <Toolbar variant="dense" className={classes.toolbar}>
        {LeftIcon && (
          <IconButton
            onClick={onButtonClick}
            color="inherit"
            className={classes.drawerButton}
          >
            <LeftIcon />
          </IconButton>
        )}
        <div className={classes.title}>
          {title && (
            <Typography variant="h6" color="inherit" className={classes.title}>
              {title}
            </Typography>
          )}
        </div>
        {children}
        {RightIcon && (
          <IconButton
            onClick={onRightButtonClick}
            color="inherit"
            className={classes.rightButton}
          >
            <RightIcon />
          </IconButton>
        )}
      </Toolbar>
    </MuiAppBar>
  );
}

export default withStyles(styles)(AppBar);
