import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { FormikProps } from "formik";
import {
  ContractorWorkType,
  TravelCostCalculation,
} from "../../../generated/graphql";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formField: {
      marginBottom: theme.spacing(1),
      flex: 1,
    },
    fieldsRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
  });

export interface ContractorFormValue {
  name: string;
  travelCost: number;
  travelCostType: TravelCostCalculation;
  hourlyRate?: number | null;
  workType: ContractorWorkType;
}

interface Props {
  formikConfig: FormikProps<ContractorFormValue>;
}

function ContractorForm({
  classes,
  formikConfig: formik,
}: Props & WithStyles<typeof styles>) {
  return (
    <div className={classes.root}>
      <TextField
        label="Firma"
        required
        className={classes.formField}
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        autoFocus
      />
      <div className={classes.fieldsRow}>
        <FormControl fullWidth className={classes.formField}>
          <InputLabel>Tätigkeit</InputLabel>
          <Select
            value={formik.values.workType}
            onChange={(e) => {
              formik.setFieldValue(
                "workType",
                e.target.value as ContractorWorkType
              );
            }}
          >
            <MenuItem value="carrier">Träger</MenuItem>
            <MenuItem value="assembly">Montage</MenuItem>
            <MenuItem value="other">Sonstiges (pauschal)</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Stundensatz"
          required={formik.values.workType !== ContractorWorkType.Other}
          disabled={formik.values.workType === ContractorWorkType.Other}
          className={classes.formField}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          type="number"
          name="hourlyRate"
          value={formik.values.hourlyRate ?? ""}
          onChange={formik.handleChange}
          error={formik.touched.hourlyRate && Boolean(formik.errors.hourlyRate)}
          helperText={formik.touched.hourlyRate && formik.errors.hourlyRate}
        />
      </div>
      <div className={classes.fieldsRow}>
        <TextField
          label="Anfahrt"
          required
          className={classes.formField}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          type="number"
          name="travelCost"
          value={formik.values.travelCost ?? ""}
          onChange={formik.handleChange}
          error={formik.touched.travelCost && Boolean(formik.errors.travelCost)}
          helperText={formik.touched.travelCost && formik.errors.travelCost}
        />
        <FormControl fullWidth className={classes.formField}>
          <InputLabel>Berechnung Anfahrt</InputLabel>
          <Select
            value={formik.values.travelCostType}
            onChange={(e) => {
              formik.setFieldValue(
                "travelCostType",
                e.target.value as TravelCostCalculation
              );
            }}
          >
            <MenuItem value="perKilometer">pro Kilometer</MenuItem>
            <MenuItem value="fixedPerEmployee">pauschal</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

export default withStyles(styles)(ContractorForm);
