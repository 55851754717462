import React from "react";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DialogProvider } from "@wa/werkstoff-core";
import germanDateFnsLocale from "date-fns/locale/de";
import theme from "../theme";
import EnhancedDateFnsUtils from "../../../util/EnhancedGermanDateFnsUtils";

class Props {
  children?: React.ReactNode;
}

export default function Application({ children }: Props) {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider
        utils={EnhancedDateFnsUtils}
        locale={germanDateFnsLocale}
      >
        <SnackbarProvider>
          <DialogProvider>
            <CssBaseline />
            {children}
          </DialogProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}
