import { PackingMaterialUnit } from "../../generated/graphql";

export function getUnit(unit?: PackingMaterialUnit) {
  return (unit ? getUnits()[unit] : null) ?? "Stk.";
}

export function getUnits() {
  return {
    [PackingMaterialUnit.Kilogram]: "kg",
    [PackingMaterialUnit.Meter]: "m",
    [PackingMaterialUnit.Piece]: "Stk.",
  };
}
