import { ApolloClient } from "@apollo/client";
import JWT from "jwt-client";
import { disconnectSubscriptionClient } from "./apolloClient";

export const serverUrl =
  process.env.NODE_ENV === "production"
    ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
    : "http://localhost:8080";

export function logout(apolloClient: ApolloClient<any>) {
  JWT.forget();
  apolloClient.clearStore();
  disconnectSubscriptionClient();
}
