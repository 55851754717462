import { useMemo, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function useSearchParams(): URLSearchParams {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
}

export function useSearchParam(
  key: string,
  defaultValue?: string
): string | undefined {
  return useSearchParams().get(key) || defaultValue;
}

export function useMutableSearchParams(): [
  URLSearchParams,
  (values: { [key: string]: string | null }) => void
] {
  const { push } = useHistory();
  const params = useSearchParams();
  return [
    params,
    useCallback(
      (values: { [key: string]: string | null }) => {
        const newParams = new URLSearchParams(window.location.search);
        for (const [key, value] of Object.entries(values)) {
          if (value != null) {
            newParams.set(key, value);
          } else {
            newParams.delete(key);
          }
        }
        push(`?${newParams.toString()}`);
      },
      [push]
    ),
  ];
}

export function useMutableSearchParam(
  key: string,
  defaultValue?: string
): [string | undefined, (value: string | null) => void] {
  const [params, setParams] = useMutableSearchParams();
  return [
    params.get(key) || defaultValue,
    useCallback((value) => setParams({ [key]: value }), [key, setParams]),
  ];
}
