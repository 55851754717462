import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { getUnit, getUnits } from "../units";
import { InvoiceItemUnit } from "../../../generated/graphql";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formField: {
      marginBottom: theme.spacing(1),
      flex: 1,
    },
    fieldsRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
    shortInput: {
      maxWidth: 120,
    },
  });

export interface InvoiceItemFormValue {
  name: string;
  unit?: InvoiceItemUnit | "" | null;
  price?: number | null;
}

interface Props {
  formikConfig: FormikProps<InvoiceItemFormValue>;
}

function InvoiceItemForm({
  classes,
  formikConfig: formik,
}: Props & WithStyles<typeof styles>) {
  return (
    <div className={classes.root}>
      <TextField
        label="Bezeichnung"
        required
        className={classes.formField}
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        autoFocus
      />
      <div className={classes.fieldsRow}>
        <FormControl
          fullWidth
          className={clsx(classes.formField, classes.shortInput)}
        >
          <InputLabel>Einheit</InputLabel>
          <Select
            value={formik.values.unit ?? ""}
            onChange={(e) => {
              formik.setFieldValue("unit", e.target.value as InvoiceItemUnit);
            }}
          >
            <MenuItem value="">(ohne Einheit)</MenuItem>
            <Divider />
            {Object.entries(getUnits())
              .sort(([, a], [, b]) => a.localeCompare(b))
              .map(([unit, name]) => (
                <MenuItem key={unit} value={unit}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          label="Preis"
          className={classes.formField}
          name="price"
          value={formik.values.price}
          onChange={formik.handleChange}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={
            (formik.touched.price && formik.errors.price) ||
            "Leer lassen, falls keine automatische Berechnung erfolgen soll"
          }
          autoFocus
          disabled={!formik.values.unit}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                €{formik.values.unit && `/${getUnit(formik.values.unit)}`}
              </InputAdornment>
            ),
          }}
          type="number"
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(InvoiceItemForm);
