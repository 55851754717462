import React from "react";
import {
  createStyles,
  Drawer,
  List,
  MenuItem,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  SofaOutline,
  Receipt,
  FileDocumentOutline,
  PackageVariant,
  Graphql,
  AccountOutline,
  CalculatorVariantOutline,
  AccountMultiplePlusOutline,
} from "mdi-material-ui";
import { blueGrey } from "@material-ui/core/colors";
import { OverflowMenu } from "@wa/werkstoff-core";
import { useHistory, useLocation } from "react-router-dom";
import JWT from "jwt-client";
import CollapsableMenuItem, {
  CollapsableMenuItemProps,
} from "../../../components/CollapsableMenuItem";
import AppBar from "../../../components/AppBar";
import { logout, serverUrl } from "../api";
import apolloClient from "../apolloClient";

const drawerWidth = 240;
const styles = ({ zIndex, spacing, transitions }: Theme) =>
  createStyles({
    appBar: {
      zIndex: zIndex.drawer + 1,
    },
    root: {
      height: "100%",
      display: "flex",
      backgroundColor: blueGrey[50],
      overflow: "auto",
    },
    menuButton: {
      margin: spacing(0, 1.5, 0, -2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      paddingTop: spacing(7),
    },
    content: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      padding: spacing(8, 2, 2),
    },
  });

interface Props {
  children: React.ReactNode;
}

function LinkMenuItem({
  path,
  ...other
}: CollapsableMenuItemProps & { path: string }) {
  const { push } = useHistory();
  const { pathname } = useLocation();
  return (
    <CollapsableMenuItem
      selected={pathname.startsWith(path)}
      onClick={() => {
        if (pathname !== path) {
          push(path);
        }
      }}
      {...other}
    />
  );
}

function AppFrame({ classes, children }: Props & WithStyles<typeof styles>) {
  const history = useHistory();

  const handleLogout = () => {
    logout(apolloClient);
    history.push("/auth/login");
  };

  return (
    <div className={classes.root}>
      <AppBar title="Wurzel Umzüge" position="fixed" className={classes.appBar}>
        <OverflowMenu>
          <MenuItem onClick={handleLogout}>Abmelden</MenuItem>
        </OverflowMenu>
      </AppBar>
      <Drawer
        variant="persistent"
        open
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List dense component="nav">
          <LinkMenuItem
            label="Umzugsgut"
            icon={<SofaOutline />}
            path="/umzugsgut"
          />
          <LinkMenuItem
            label="Packmaterial"
            icon={<PackageVariant />}
            path="/packmaterial"
          />
          <LinkMenuItem
            label="Rechnungspositionen"
            icon={<Receipt />}
            path="/rechnungspositionen"
          />
          <LinkMenuItem
            label="Kalkulationssätze"
            icon={<CalculatorVariantOutline />}
            path="/kalkulationssaetze"
          />
          <LinkMenuItem
            label="Fremdfirmen"
            icon={<AccountMultiplePlusOutline />}
            path="/fremdfirmen"
          />
          <LinkMenuItem
            label="Vorlagen"
            icon={<FileDocumentOutline />}
            path="/vorlagen"
          />
          <LinkMenuItem
            label="Benutzer"
            icon={<AccountOutline />}
            path="/benutzer"
          />
          {process.env.NODE_ENV !== "production" && (
            <LinkMenuItem
              path="/graphql"
              label="GraphQL Playground"
              icon={<Graphql />}
              onClick={async () => {
                window.open(
                  `${serverUrl}/graphql?headers=${encodeURIComponent(
                    JSON.stringify({
                      Authorization: JWT.get(),
                    })
                  )}`
                );
              }}
            />
          )}
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
}

export default withStyles(styles)(AppFrame);
