import {
  ApolloClient,
  ApolloLink,
  split,
  InMemoryCache,
  ServerError,
  concat,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import JWT from "jwt-client";
import omitDeep from "@wertarbyte/omit-deep";
import { serverUrl } from "./api";
import { OperationDefinitionNode } from "graphql";
import { createUploadLink } from "apollo-upload-client";

const authLink = setContext((_, { headers }) => {
  const token = JWT.get();
  return {
    headers: {
      ...headers,
      Authorization: token ?? "",
    },
  };
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ["__typename"]; // more keys like timestamps could be included here

  const definition = getMainDefinition(operation.query);
  if ((definition as OperationDefinitionNode)?.operation === "mutation") {
    operation.variables = omitDeep(operation.variables, keysToOmit, false);
  }
  return forward ? forward(operation) : null;
});

const uploadLink = (createUploadLink({
  uri: `${serverUrl}/graphql`,
}) as unknown) as ApolloLink; // types are bad (https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369)

const wsLink = new WebSocketLink({
  uri: `ws${serverUrl.slice(4)}/graphql`,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = JWT.get();
      return { Authorization: token ?? "" };
    },
  },
});

const cache = new InMemoryCache();

export default new ApolloClient({
  /* eslint-disable no-console */
  link: ApolloLink.from([
    cleanTypenameLink,
    onError(({ networkError, graphQLErrors }) => {
      if (
        (networkError as ServerError)?.statusCode === 401 ||
        graphQLErrors?.some((e) => e.extensions?.exception?.status === 401)
      ) {
        // not logged in
        JWT.forget();
        window.location.href = "/";
        // if (window.location.pathname !== "/auth/login") {
        //   window.location.href = "/auth/login";
        // }
      }
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map((error) => console.error("GraphQL error", error));
      }
      if (networkError) console.error("Network error", networkError);
    }),
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      concat(authLink, uploadLink)
    ),
  ]),
  cache,
});

export function clearCache() {
  return cache.reset();
}

export function disconnectSubscriptionClient() {
  // tslint:disable-next-line: no-string-literal
  wsLink["subscriptionClient"].close(true, true);
}

export function reconnectSubscriptionClient() {
  disconnectSubscriptionClient();
  // tslint:disable-next-line: no-string-literal
  wsLink["subscriptionClient"].connect();
}
