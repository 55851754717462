import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
  InputAdornment,
  Typography,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@material-ui/core";
import { FormikProps } from "formik";
import FormikTextField from "../../../components/FormikTextField";
import {
  CalculationParameters,
  emptyConfiguration,
  StaffCategories,
  Vehicles,
} from "@wurzel/uzb-sync/dist/Configuration";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& table": {
        maxWidth: 700,
        "&:not(:last-child)": {
          marginBottom: theme.spacing(2),
        },
      },
    },
    fields: {
      display: "grid",
      gridGap: theme.spacing(2),
      gridTemplateColumns: "repeat(auto-fit,230px)",
      "&:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },
    formField: {
      marginBottom: theme.spacing(1),
    },
    fieldsRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
  });

export interface CalculationParametersFormValue {
  calculationParameters: CalculationParameters;
  staffCategories: StaffCategories;
  vehicles: Vehicles;
}

export const emptyValue: CalculationParametersFormValue = {
  calculationParameters: emptyConfiguration.calculationParameters,
  staffCategories: emptyConfiguration.staffCategories,
  vehicles: emptyConfiguration.vehicles,
};

interface Props {
  formikConfig: FormikProps<CalculationParametersFormValue>;
}

function PackingMaterialForm({
  classes,
  formikConfig: formik,
}: Props & WithStyles<typeof styles>) {
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography color="primary" gutterBottom>
          Lagerkosten
        </Typography>
        <div className={classes.fields}>
          <FormikTextField
            formikConfig={formik}
            label="Lagermiete Wurzel"
            className={classes.formField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€/(m³ u. Monat)</InputAdornment>
              ),
            }}
            type="number"
            name="calculationParameters.storageRent"
          />
          <FormikTextField
            formikConfig={formik}
            label="Lagermiete Container"
            className={classes.formField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€/Tag</InputAdornment>
              ),
            }}
            type="number"
            name="calculationParameters.containerRent"
          />
          <FormikTextField
            formikConfig={formik}
            label="Containerhandling"
            className={classes.formField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€/Stk.</InputAdornment>
              ),
            }}
            type="number"
            name="calculationParameters.containerHandling"
          />
          <FormikTextField
            formikConfig={formik}
            label="An- und Abfahrt Container"
            className={classes.formField}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            type="number"
            name="calculationParameters.containerTransport"
          />
        </div>

        <Typography color="primary" gutterBottom>
          Sonstiges
        </Typography>
        <div className={classes.fields}>
          <FormikTextField
            formikConfig={formik}
            label="Halteverbotszone einrichten"
            className={classes.formField}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            type="number"
            name="calculationParameters.noParkingZone"
          />
          <FormikTextField
            formikConfig={formik}
            label="Entsorgung Sperrmüll"
            className={classes.formField}
            InputProps={{
              endAdornment: <InputAdornment position="end">€/t</InputAdornment>,
            }}
            type="number"
            name="calculationParameters.bulkyWasteDisposal"
          />
          <FormikTextField
            formikConfig={formik}
            label="Anlieferung Packmaterial"
            className={classes.formField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€/km</InputAdornment>
              ),
            }}
            type="number"
            name="calculationParameters.kilometerRatePackingMaterial"
          />
          <FormikTextField
            formikConfig={formik}
            label="Nur Möbelwagen einsetzen bis"
            className={classes.formField}
            InputProps={{
              endAdornment: <InputAdornment position="end">RE</InputAdornment>,
            }}
            type="number"
            name="calculationParameters.trailerThreshold"
          />
        </div>

        <Typography color="primary" gutterBottom>
          Versicherung
        </Typography>
        <div className={classes.fields}>
          <FormikTextField
            formikConfig={formik}
            label="Anteil vom Neuwert"
            className={classes.formField}
            type="number"
            name="calculationParameters.insuranceFactor"
          />
          <FormikTextField
            formikConfig={formik}
            label="Mindestbeitrag"
            className={classes.formField}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            type="number"
            name="calculationParameters.insuranceMinimum"
          />
        </div>
      </Paper>
      <Paper className={classes.paper}>
        <Typography color="primary" gutterBottom>
          Personal
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Kategorie</TableCell>
              <TableCell>Tagessatz</TableCell>
              <TableCell>Stundensatz</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Berater</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.consultant.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.consultant.hourlyRate"
                  margin="none"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Teamleiter</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.teamLeader.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.teamLeader.hourlyRate"
                  margin="none"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Träger</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.carrier.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.carrier.hourlyRate"
                  margin="none"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Azubi</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.trainee.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.trainee.hourlyRate"
                  margin="none"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Aushilfe</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.temporaryEmployee.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.temporaryEmployee.hourlyRate"
                  margin="none"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Glaspacker</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.glassPacker.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="staffCategories.glassPacker.hourlyRate"
                  margin="none"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Paper className={classes.paper}>
        <Typography color="primary" gutterBottom>
          KFZ und LKW
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Bezeichnung/Typ</TableCell>
              <TableCell>Tagessatz</TableCell>
              <TableCell>Stundensatz</TableCell>
              <TableCell>Kilometersatz</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>PKW Berater</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.consultantCar.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Möbelwagen</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/Tag</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.furnitureTruck.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/Std.</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.furnitureTruck.hourlyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/km</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.furnitureTruck.kilometerRate"
                  margin="none"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Anhänger</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/Tag</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.trailer.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/Std.</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.trailer.hourlyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/km</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.trailer.kilometerRate"
                  margin="none"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fahrzeug Packer</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/Tag</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.staffCar.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/Std.</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.staffCar.hourlyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/km</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.staffCar.kilometerRate"
                  margin="none"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Außenaufzug</TableCell>
              <TableCell>
                <FormikTextField
                  formikConfig={formik}
                  className={classes.formField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/Tag</InputAdornment>
                    ),
                  }}
                  type="number"
                  name="vehicles.lift.dailyRate"
                  margin="none"
                />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
        <Typography color="primary" gutterBottom>
          Maut
        </Typography>
        <div className={classes.fields}>
          <FormikTextField
            formikConfig={formik}
            label="LKW (ohne Anhänger)"
            className={classes.formField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€/km</InputAdornment>
              ),
            }}
            type="number"
            name="calculationParameters.truckToll"
          />
          <FormikTextField
            formikConfig={formik}
            label="LKW (mit Anhänger)"
            className={classes.formField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€/km</InputAdornment>
              ),
            }}
            type="number"
            name="calculationParameters.truckTollWithTrailer"
          />
        </div>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(PackingMaterialForm);
