import React from "react";
import {
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { TooltipIconButton } from "@wa/werkstoff-core";
import { ArrowLeft } from "mdi-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
      height: theme.spacing(5),
      "& > div": {
        display: "flex",
        alignItems: "center",
        "&:first-child > button:first-child": {
          marginRight: theme.spacing(1),
        },
      },
      "& .MuiIconButton-root": {
        padding: theme.spacing(1),
        color: theme.palette.action.active,
      },
    },
  });

interface ActionBarProps {
  title?: string;
  onBack?: () => void;
  backButtonLabel?: string;
  children?: React.ReactNode;
}

function ActionBar({
  classes,
  children,
  title,
  onBack,
  backButtonLabel,
}: ActionBarProps & WithStyles<typeof styles>) {
  return (
    <div className={classes.root}>
      <div>
        {onBack && (
          <TooltipIconButton tooltip={backButtonLabel} onClick={onBack}>
            <ArrowLeft />
          </TooltipIconButton>
        )}
        {title && <Typography variant="h6">{title}</Typography>}
      </div>
      <div>{children}</div>
    </div>
  );
}

export default withStyles(styles)(ActionBar);
