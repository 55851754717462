import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { TooltipIconButton } from "@wa/werkstoff-core";
import { gql } from "graphql.macro";
import { useSnackbar } from "material-ui-snackbar-provider";
import { Plus } from "mdi-material-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActionBar from "../../components/ActionBar";
import { GetRoomsDocument, CreateRoomDocument } from "../../generated/graphql";

gql`
  query GetRooms {
    rooms {
      id
      name
      items {
        id
      }
    }
  }
`;

gql`
  mutation CreateRoom($room: RoomInput!) {
    createRoom(room: $room) {
      id
      name
      items {
        id
      }
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: "pointer",
    },
    placeholder: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
  });

function RoomsContainer({ classes }: WithStyles<typeof styles>) {
  const { push } = useHistory();
  const snackbar = useSnackbar();

  const { data } = useQuery(GetRoomsDocument);
  const handleShowRoom = useCallback(
    (e) => {
      push(`/umzugsgut/${e.currentTarget.dataset.room}`);
    },
    [push]
  );

  const [showAddRoomDialog, setShowAddRoomDialog] = useState(false);
  const [newRoomName, setNewRoomName] = useState("");
  useEffect(() => {
    if (showAddRoomDialog) {
      setNewRoomName("");
    }
  }, [showAddRoomDialog]);

  const [createRoom] = useMutation(CreateRoomDocument, {
    refetchQueries: [{ query: GetRoomsDocument }],
  });
  const handleCreateRoom = useCallback(async () => {
    try {
      const { data } = await createRoom({
        variables: {
          room: {
            name: newRoomName,
            items: [],
          },
        },
      });
      push(`/umzugsgut/${data?.createRoom.id}`);
    } catch (e) {
      console.error("Failed to create room", e);
      snackbar.showMessage("Der Raum konnte nicht erstellt werden.");
    }
  }, [createRoom, snackbar, push, newRoomName]);

  return (
    <>
      <ActionBar title="Umzugsgut">
        <TooltipIconButton
          tooltip="Raum hinzufügen"
          onClick={() => setShowAddRoomDialog(true)}
        >
          <Plus />
        </TooltipIconButton>
      </ActionBar>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Raum</TableCell>
              <TableCell>Gegenstände</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.rooms.map((room) => (
              <TableRow
                key={room.id}
                onClick={handleShowRoom}
                hover
                data-room={room.id}
                className={classes.tableRow}
              >
                <TableCell>{room.name}</TableCell>
                <TableCell>{room.items.length}</TableCell>
              </TableRow>
            ))}
            {data?.rooms.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} className={classes.placeholder}>
                  Sie haben noch keine Räume konfiguriert.
                  <br />
                  Klicken Sie auf &bdquo;+&ldquo;, um den ersten Raum
                  hinzuzufügen.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={showAddRoomDialog}
        onClose={() => setShowAddRoomDialog(false)}
        disableBackdropClick
        fullWidth
      >
        <DialogTitle>Raum hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            label="Bezeichnung"
            value={newRoomName}
            onChange={(e) => setNewRoomName(e.target.value)}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddRoomDialog(false)} tabIndex={1}>
            Abbrechen
          </Button>
          <Button
            color="primary"
            disabled={newRoomName.trim().length === 0}
            onClick={handleCreateRoom}
            tabIndex={0}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(RoomsContainer);
