import React from "react";
import { Switch, Route } from "react-router-dom";
import RoomsContainer from "./Rooms";
import RoomContainer from "./Room";

export default function RoomsRoutes() {
  return (
    <Switch>
      <Route path="/umzugsgut/:id" component={RoomContainer} />
      <Route component={RoomsContainer} />
    </Switch>
  );
}
