import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
  TextField,
} from "@material-ui/core";
import { FormikProps } from "formik";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formField: {
      marginBottom: theme.spacing(1),
      flex: 1,
    },
    fieldsRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
    mediumInput: {
      maxWidth: 200,
    },
  });

export interface UserFormValue {
  username: string;
  fullName: string;
  signaturePrefix?: string | null;
  password?: string | null;
}

interface Props {
  formikConfig: FormikProps<UserFormValue>;
  isNew?: boolean;
}

function UserForm({
  classes,
  formikConfig: formik,
  isNew,
}: Props & WithStyles<typeof styles>) {
  return (
    <div className={classes.root}>
      <div className={classes.fieldsRow}>
        <TextField
          label="Benutzername"
          required
          className={classes.formField}
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
        />
      </div>
      <div className={classes.fieldsRow}>
        <TextField
          label="Präfix für Signatur"
          placeholder="i.A."
          className={clsx(classes.formField, classes.mediumInput)}
          name="signaturePrefix"
          value={formik.values.signaturePrefix || ""}
          onChange={formik.handleChange}
        />
        <TextField
          label="Vollständiger Name"
          required
          className={classes.formField}
          name="fullName"
          value={formik.values.fullName}
          onChange={formik.handleChange}
        />
      </div>
      <div className={classes.fieldsRow}>
        <TextField
          label="Passwort"
          required={isNew}
          className={classes.formField}
          type="password"
          name="password"
          value={formik.values.password || ""}
          onChange={formik.handleChange}
          autoComplete="new-password"
          helperText={
            isNew ? undefined : "Leer lassen, um das Passwort nicht zu ändern"
          }
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(UserForm);
