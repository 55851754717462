import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { getUnit, getUnits } from "../../packingMaterial/units";
import { PackingMaterialUnit } from "../../../generated/graphql";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formField: {
      marginBottom: theme.spacing(1),
      flex: 1,
    },
    fieldsRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
  });

export interface PackingMaterialFormValue {
  name: string;
  price?: number;
  unit: PackingMaterialUnit;
}

interface Props {
  formikConfig: FormikProps<PackingMaterialFormValue>;
}

function PackingMaterialForm({
  classes,
  formikConfig: formik,
}: Props & WithStyles<typeof styles>) {
  return (
    <div className={classes.root}>
      <TextField
        label="Bezeichnung"
        required
        className={classes.formField}
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        autoFocus
      />
      <div className={classes.fieldsRow}>
        <FormControl fullWidth className={classes.formField}>
          <InputLabel>Einheit</InputLabel>
          <Select
            value={formik.values.unit}
            onChange={(e) => {
              formik.setFieldValue(
                "unit",
                e.target.value as PackingMaterialUnit
              );
            }}
          >
            {Object.entries(getUnits())
              .sort(([, a], [, b]) => a.localeCompare(b))
              .map(([unit, name]) => (
                <MenuItem key={unit} value={unit}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          label="Preis"
          required
          className={classes.formField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                €/{getUnit(formik.values.unit)}
              </InputAdornment>
            ),
          }}
          type="number"
          name="price"
          value={formik.values.price ?? ""}
          onChange={formik.handleChange}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(PackingMaterialForm);
