import { InvoiceItemUnit } from "../../generated/graphql";

export function getUnit(unit?: InvoiceItemUnit | null) {
  return (unit ? getUnits()[unit] : null) ?? "";
}

export function getUnits() {
  return {
    [InvoiceItemUnit.Kilogram]: "kg",
    [InvoiceItemUnit.Meter]: "m",
    [InvoiceItemUnit.Piece]: "Stk.",
    [InvoiceItemUnit.Ton]: "t",
    [InvoiceItemUnit.Hour]: "Std.",
  };
}
