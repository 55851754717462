import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#2D3586",
    },
    secondary: {
      main: "#88B831",
    },
    type: "light",
  },
  props: {
    MuiButton: {
      size: "small",
    },
  },
});
