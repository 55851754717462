import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { AsyncButton } from "@wa/werkstoff-core";
import { gql } from "graphql.macro";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { saveAs } from "file-saver";
import * as JWT from "jwt-client";
import { applyTemplateToWorkbook } from "@wurzel/uzb-templates/dist/templateEngine";
import * as ExcelJS from "exceljs";
import ActionBar from "../../components/ActionBar";
import {
  GetTemplatesDocument,
  UploadTemplateDocument,
  Template,
} from "../../generated/graphql";
import { serverUrl } from "../core/api";
import * as placeholders from "./doc/placeholders";
import { setDeep } from "../../util/setDeep";
import { Upload, Download, FileDocument } from "mdi-material-ui";
import { useSearchParamDialogOpen } from "../../hooks/useDialogOpen";
import { TemplateType } from "@wurzel/uzb-templates";

gql`
  query GetTemplates {
    templates {
      id
      type
      updatedAt
    }
  }
`;

gql`
  mutation uploadTemplate($templateType: TemplateType!, $template: Upload!) {
    uploadTemplate(templateType: $templateType, template: $template) {
      id
      type
      updatedAt
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: "pointer",
    },
    placeholder: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
    removeDialogButton: {
      color: theme.palette.error.dark,
      marginRight: "auto",
    },
    moreItems: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      "& > .MuiFormControlLabel-label": {
        ...theme.typography.body2,
      },
    },
    input: {
      display: "none",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: 64,
      paddingTop: 0,
    },
    loader: {
      position: "absolute",
      left: "50%",
      top: "50%",
      margin: "-20px 0 0 -20px",
    },
    placeholders: {
      marginTop: theme.spacing(1),
      "& table": {
        width: "100%",
      },
      "& th": {
        textAlign: "left",
      },
    },
    buttonIcon: {
      fontSize: "18px",
      marginRight: theme.spacing(0.5),
    },
    flex: {
      flex: 1,
    },
  });

const xlsxMimeType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

function Templates({ classes }: WithStyles<typeof styles>) {
  const snackbar = useSnackbar();

  const { data } = useQuery(GetTemplatesDocument);

  const [uploadTemplate] = useMutation(UploadTemplateDocument, {
    refetchQueries: [{ query: GetTemplatesDocument }],
  });

  const templates = useMemo(
    () =>
      [
        TemplateType.OfferLetter,
        TemplateType.Offer,
        TemplateType.OfferConfirmation,
        TemplateType.WorkInstructions,
        TemplateType.WorkSlip,
        TemplateType.PackingMaterialDelivery,
        TemplateType.FeedbackLetter,
        TemplateType.Invoice,
        TemplateType.PackingMaterialPickup,
      ].map((type) => ({
        type,
        template: data?.templates.find((t) => t.type === type),
      })),
    [data?.templates]
  );

  const templateDialog = useSearchParamDialogOpen("template");
  const selectedTemplateType = templateDialog.data;
  const [selectedTemplate, setSelectedTemplate] = useState<
    Pick<Template, "id" | "type" | "updatedAt">
  >();
  useEffect(() => {
    const template = templates?.find((t) => t.type === selectedTemplateType);
    if (template != null) {
      setSelectedTemplate(template.template);
    }
  }, [templates, selectedTemplateType]);

  const handleRowClick = useCallback(
    (e: MouseEvent<HTMLTableRowElement>) => {
      templateDialog.handleOpen(e.currentTarget.dataset.itemType as string);
    },
    [templateDialog]
  );

  const [uploading, setUploading] = useState(false);
  const handleUploadNewTemplate = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const template = e.currentTarget.files?.[0];
      e.currentTarget.value = ""; // allow uploading the same file twice
      if (template) {
        setUploading(true);
        try {
          await uploadTemplate({
            variables: {
              templateType: selectedTemplateType as TemplateType,
              template,
            },
          });
          snackbar.showMessage("Die neue Vorlage wurde hochgeladen.");
        } catch (e) {
          snackbar.showMessage(
            "Die neue Vorlage konnte nicht hochgeladen werden."
          );
        } finally {
          setUploading(false);
        }
      }
    },
    [uploadTemplate, snackbar, selectedTemplateType]
  );

  const handleDownloadTemplate = useCallback(async () => {
    if (!selectedTemplateType) return;
    try {
      const res = await fetch(
        `${serverUrl}/api/templates/${selectedTemplateType}`,
        {
          method: "GET",
          headers: {
            Authorization: JWT.get(),
          },
        }
      );
      if (res.status !== 200) {
        throw new Error(res.statusText + " " + (await res.text()));
      }
      const file = await res.blob();
      saveAs(file, `${getTemplateName(selectedTemplateType)}.xlsx`);
    } catch (e) {
      snackbar.showMessage("Die Vorlage konnte nicht heruntergeladen werden.");
      console.error(e);
    }
  }, [selectedTemplateType, snackbar]);

  const handleDownloadExample = useCallback(async () => {
    if (!selectedTemplateType) return;
    try {
      const res = await fetch(
        `${serverUrl}/api/templates/${selectedTemplateType}`,
        {
          method: "GET",
          headers: {
            Authorization: JWT.get(),
          },
        }
      );
      if (res.status !== 200) {
        throw new Error(res.statusText + " " + (await res.text()));
      }
      const file = await res.blob();

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(await file.arrayBuffer());

      const variables = {};
      for (const [name, , example] of getTemplateVariables(
        selectedTemplateType ?? ""
      )) {
        if (/^\d+,\d+$/.test(example)) {
          setDeep(variables, name, example.replace(",", "."));
        } else {
          setDeep(variables, name, example);
        }
      }

      const { unknownVariables } = applyTemplateToWorkbook(
        workbook,
        variables,
        {
          highlightUnknownVariables: true,
        }
      );
      if (unknownVariables.length > 0) {
        snackbar.showMessage(
          "Unbekannte Platzhalter wurden in der Vorschau rot markiert."
        );
      }

      const data = await workbook.xlsx.writeBuffer();
      const blob = new Blob([data], { type: xlsxMimeType });
      saveAs(blob, `Vorschau ${getTemplateName(selectedTemplateType)}.xlsx`);
    } catch (e) {
      snackbar.showMessage("Die Vorschau konnte nicht erzeugt werden.");
      console.error(e);
    }
  }, [selectedTemplateType, snackbar]);

  return (
    <>
      <ActionBar title="Vorlagen" />
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Dokument</TableCell>
              <TableCell>Zuletzt aktualisiert</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates?.map((item) => (
              <TableRow
                key={item.type}
                hover
                data-item-type={item.type}
                className={classes.tableRow}
                onClick={handleRowClick}
              >
                <TableCell>{getTemplateName(item.type)}</TableCell>
                <TableCell>
                  {item.template
                    ? new Date(item.template.updatedAt).toLocaleString()
                    : "–"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog
        open={templateDialog.open}
        fullWidth
        maxWidth="md"
        disableBackdropClick={uploading}
      >
        <DialogTitle>
          Vorlage: {getTemplateName(selectedTemplateType ?? "")}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {data?.templates != null ? (
            <>
              <div>
                {selectedTemplate != null
                  ? `Die aktuelle Vorlage wurde zuletzt aktualisiert am ${new Date(
                      selectedTemplate.updatedAt
                    ).toLocaleString()}`
                  : "Es gibt noch keine Vorlage für dieses Dokument."}
              </div>
              <div>
                <AsyncButton
                  variant="text"
                  color="primary"
                  onClick={handleDownloadTemplate}
                  disabled={selectedTemplate == null}
                >
                  <Download className={classes.buttonIcon} />
                  Vorlage herunterladen
                </AsyncButton>
                <input
                  accept={xlsxMimeType}
                  className={classes.input}
                  id="upload-template-file"
                  multiple
                  type="file"
                  onChange={handleUploadNewTemplate}
                />
                <label htmlFor="upload-template-file">
                  <Button
                    variant="text"
                    component="span"
                    color="primary"
                    disabled={uploading}
                  >
                    <Upload className={classes.buttonIcon} />
                    {selectedTemplate != null
                      ? "Neue Vorlage hochladen"
                      : "Vorlage hochladen"}
                  </Button>
                </label>
                <AsyncButton
                  variant="text"
                  color="primary"
                  onClick={handleDownloadExample}
                  disabled={selectedTemplate == null}
                >
                  <FileDocument className={classes.buttonIcon} />
                  Vorschau mit Beispieldaten
                </AsyncButton>
              </div>
              <div className={classes.placeholders}>
                <Typography variant="overline" color="primary">
                  Platzhalter für die Vorlage
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Die Platzhalter werden in allen Zellen durch die
                  entsprechenden Informationen aus dem Umzug ersetzt. Außerdem
                  können wie gewohnt Excel-Formeln genutzt werden, um die
                  Vorlage an Ihre Anforderungen anzupassen.
                </Typography>
                <table>
                  <thead>
                    <tr>
                      <th>Platzhalter</th>
                      <th>Beschreibung</th>
                      <th>Beispiel</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getTemplateVariables(selectedTemplateType ?? "")
                      .filter(([, , , options]) => !options?.hide)
                      .map(([variable, description, example, options]) => (
                        <tr key={variable}>
                          <td>{options?.displayName ?? `{${variable}}`}</td>
                          <td>{description}</td>
                          <td>{example}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <CircularProgress className={classes.loader} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={templateDialog.handleClose}
            color="primary"
            disabled={uploading}
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function getTemplateName(type: string) {
  switch (type) {
    case TemplateType.OfferLetter:
      return "Anschreiben Umzugsvertrag";
    case TemplateType.Offer:
      return "Umzugsvertrag";
    case TemplateType.OfferConfirmation:
      return "Auftragsbestätigung";
    case TemplateType.WorkInstructions:
      return "Arbeitsanweisung";
    case TemplateType.PackingMaterialDelivery:
      return "Anlieferung Packmaterial";
    case TemplateType.PackingMaterialPickup:
      return "Abholung Packmaterial";
    case TemplateType.FeedbackLetter:
      return "Anschreiben Fragebogen";
    case TemplateType.WorkSlip:
      return "Arbeitsschein";
    case TemplateType.Invoice:
      return "Rechnung";
    default:
      return "Unbekannte Vorlage";
  }
}

function getTemplateVariables(type: string) {
  switch (type) {
    case TemplateType.OfferLetter:
      return placeholders.offerLetter;
    case TemplateType.Offer:
      return placeholders.offer;
    case TemplateType.OfferConfirmation:
      return placeholders.offerConfirmation;
    case TemplateType.WorkInstructions:
      return placeholders.workInstructions;
    case TemplateType.PackingMaterialDelivery:
      return placeholders.packingMaterialDelivery;
    case TemplateType.PackingMaterialPickup:
      return placeholders.packingMaterialPickup;
    case TemplateType.FeedbackLetter:
      return placeholders.feedbackLetter;
    case TemplateType.WorkSlip:
      return placeholders.workSlip;
    case TemplateType.Invoice:
      return placeholders.invoice;
    default:
      return [];
  }
}

export default withStyles(styles)(Templates);
