import { TextField, TextFieldProps } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";

function get(obj: any, path: string) {
  let current = obj;
  for (const p of path.split(".")) {
    current = current[p];
    if (!current) {
      return current;
    }
  }
  return current;
}

type FormikTextFieldProps<Values extends object> = TextFieldProps & {
  formikConfig: FormikProps<Values>;
  name: string;
};

export default function FormikTextField<T extends object>({
  formikConfig,
  name,
  ...other
}: FormikTextFieldProps<T>) {
  return (
    <TextField
      name={name}
      value={get(formikConfig.values, name) ?? ""}
      onChange={formikConfig.handleChange}
      error={
        get(formikConfig.touched, name) &&
        Boolean(get(formikConfig.errors, name))
      }
      helperText={
        get(formikConfig.touched, name) && get(formikConfig.errors, name)
      }
      {...other}
    />
  );
}
