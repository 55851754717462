import React, { useState, useEffect } from "react";
import JWT from "jwt-client";
import AuthenticationDialog from "./AuthenticationDialog";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

export default function AuthenticationContainer({ children }: Props) {
  const [authenticated, setAuthenticated] = useState(false);
  const { push } = useHistory();
  const location = useLocation();

  useEffect(() => {
    // auto login
    try {
      setAuthenticated(JWT.validate(JWT.read(JWT.get())));
    } catch {}
  }, []);

  const showAuthDialog = !authenticated || location.pathname === "/auth/login";

  return (
    <>
      {!showAuthDialog && children}
      <AuthenticationDialog
        open={showAuthDialog}
        onClose={() => {
          setAuthenticated(true);
          if (location.pathname === "/auth/login") {
            push("/");
          }
        }}
      />
    </>
  );
}
