import { serverUrl } from "../core/api";

export function getAccessToken(accessKey: string) {
  return fetch(`${serverUrl}/api/authentication`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ accessKey }),
  }).then((res) => {
    if (res.status === 401) {
      throw new Error("Wrong password");
    } else if (res.status !== 201) {
      throw new Error("Not authenticated");
    }
    return res.json();
  });
}
