import { useMutation, useQuery } from "@apollo/client";
import { AsyncButton, TooltipIconButton } from "@wa/werkstoff-core";
import { useFormik } from "formik";
import { gql } from "graphql.macro";
import { useSnackbar } from "material-ui-snackbar-provider";
import { Check } from "mdi-material-ui";
import React from "react";
import { Prompt } from "react-router";
import { emptyConfiguration } from "@wurzel/uzb-sync";
import ActionBar from "../../components/ActionBar";
import {
  GetCalculationParametersDocument,
  UpdateCalculationParametersDocument,
} from "../../generated/graphql";
import CalculationParametersForm, {
  CalculationParametersFormValue,
} from "./components/CalculationParametersForm";

gql`
  query GetCalculationParameters {
    staffCategories {
      consultant {
        id
        hourlyRate
        dailyRate
      }
      teamLeader {
        id
        dailyRate
        hourlyRate
      }
      carrier {
        id
        dailyRate
        hourlyRate
      }
      trainee {
        id
        dailyRate
        hourlyRate
      }
      temporaryEmployee {
        id
        dailyRate
        hourlyRate
      }
      glassPacker {
        id
        dailyRate
        hourlyRate
      }
    }
    calculationParameters {
      storageRent
      containerRent
      containerHandling
      containerTransport
      noParkingZone
      bulkyWasteDisposal
      kilometerRatePackingMaterial
      trailerThreshold
      insuranceFactor
      insuranceMinimum
      truckToll
      truckTollWithTrailer
    }
    vehicles {
      consultantCar {
        id
        hourlyRate
        dailyRate
        kilometerRate
      }
      furnitureTruck {
        id
        hourlyRate
        dailyRate
        kilometerRate
      }
      trailer {
        id
        hourlyRate
        dailyRate
        kilometerRate
      }
      lift {
        id
        hourlyRate
        dailyRate
        kilometerRate
      }
      staffCar {
        id
        hourlyRate
        dailyRate
        kilometerRate
      }
    }
  }
`;

gql`
  mutation UpdateCalculationParameters(
    $calculationParameters: CalculationParametersInput!
    $staffCategories: StaffCategoriesInput!
    $vehicles: VehiclesInput!
  ) {
    updateCalculationParameters(calculationParameters: $calculationParameters) {
      storageRent
    }
    updateStaffCategories(staffCategories: $staffCategories) {
      consultant {
        id
      }
    }
    updateVehicles(vehicles: $vehicles) {
      consultantCar {
        id
      }
    }
  }
`;

export default function RoomsContainer() {
  const snackbar = useSnackbar();

  const { data } = useQuery(GetCalculationParametersDocument);

  const [updateCalculationParameters] = useMutation(
    UpdateCalculationParametersDocument,
    {
      refetchQueries: [{ query: GetCalculationParametersDocument }],
    }
  );
  const formik = useFormik<CalculationParametersFormValue>({
    initialValues: data || emptyConfiguration,
    onSubmit: async (value) => {
      try {
        await updateCalculationParameters({
          variables: {
            calculationParameters: value.calculationParameters,
            staffCategories: Object.fromEntries(
              Object.entries(value.staffCategories).map(
                ([type, { hourlyRate, dailyRate }]) => [
                  type,
                  { hourlyRate, dailyRate }, // remove __typename and id
                ]
              )
            ),
            vehicles: Object.fromEntries(
              Object.entries(value.vehicles).map(
                ([type, { hourlyRate, dailyRate, kilometerRate }]) => [
                  type,
                  { hourlyRate, dailyRate, kilometerRate }, // remove __typename and id
                ]
              )
            ),
          },
        });
        snackbar.showMessage("Die Kalkulationssätze wurden gespeichert.");
      } catch (e) {
        snackbar.showMessage(
          "Die Kalkulationssätze konnten nicht gespeichert werden."
        );
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <ActionBar title="Kalkulationssätze">
        <AsyncButton
          component={TooltipIconButton}
          tooltip="Änderungen speichern"
          onClick={() => formik.submitForm()}
        >
          <Check />
        </AsyncButton>
      </ActionBar>
      <CalculationParametersForm formikConfig={formik} />
      <Prompt
        when={formik.dirty}
        message="Es gibt ungespeicherte Änderungen. Wenn Sie diese Seite verlassen, werden die Änderungen verworfen."
      />
    </>
  );
}
