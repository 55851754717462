type Placeholders = Array<
  [string, string, string, { hide: boolean; displayName: string }?]
>;

const customer: Placeholders = [
  ["kunde.anrede", "Anrede des Kunden (Firmenname bei Firmen)", "Frau"],
  ["kunde.name", "Vollständiger Name des Kunden", "Erika Mustermann"],
  ["kunde.vorname", "Vorname des Kunden", "Erika"],
  ["kunde.nachname", "Nachname des Kunden", "Mustermann"],
  ["kunde.anschrift", "Anschrift des Kunden", "Musterstraße 1"],
  ["kunde.anschrift2", "Anschrift des Kunden (2. Zeile)", ""],
  ["kunde.plz", "Postleitzahl des Kunden", "12345"],
  ["kunde.ort", "Ort des Kunden", "Musterstadt"],
  ["kunde.istFirma", "Falls der Kunde ein Firmenkunde ist 1, sonst 0", "0"],
  ["kunde.firma", "Firmenname", ""],
  [
    "kunde.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
];

export const offerLetter: Placeholders = [
  ...customer,
  ["beladestelle", "Beladestelle (Ort)", "Musterstadt"],
  ["entladestelle", "Entladestelle (Ort)", "Musterhausen"],
  ["datum", "Geplantes Datum für die Beladung", "01.01.2021"],
  ["datumAlternativ", "Alternatives Datum für die Beladung", "02.01.2021"],
  ["angebotsdatum", "Angebotsdatum", "06.12.2020"],
  ["preisNetto", "Netto-Preis", "2738,24"],
  ["preisBrutto", "Brutto-Preis", "3258,51"],
  ["versicherungZeitwert", "Versicherungssumme zum Zeitwert", "29264,00"],
  [
    "versicherungAnteil",
    "Versicherungsbeitrag bei Neuwertversicherung (Anteil des Neuwerts in Prozent)",
    "0,3",
  ],
  [
    "versicherungMindestbeitrag",
    "Mindestversicherungsbeitrag bei Neuwertversicherung (Euro)",
    "35",
  ],
  ["zeitraum", "Geplanter Zeitraum für die Beladung", "7:00 bis 7:30 Uhr"],
  ["unterschrift", "Unterschrift des Beraters", "ppa. M. Mustermann"],
  [
    "packmaterialAnlieferungDatum",
    "Datum für Anlieferung des Packmaterials",
    "20.12.2020",
  ],
  ["kundenrabatt", "Kundenrabatt in Euro (positiv)", "0,00"],
  ["kundenrabattProzent", "Kundenrabatt in Prozent vom Netto-Preis", "0,00"],
];

export const offer: Placeholders = [
  ...customer,
  [
    "beladestelle.anrede",
    "Anrede Beladestelle (Firmenname bei Firmen)",
    "Frau",
  ],
  ["beladestelle.name", "Vollständiger Name Beladestelle", "Erika Mustermann"],
  ["beladestelle.vorname", "Vorname Beladestelle", "Erika"],
  ["beladestelle.nachname", "Nachname Beladestelle", "Mustermann"],
  ["beladestelle.anschrift", "Anschrift Beladestelle", "Musterstraße 1"],
  ["beladestelle.anschrift2", "Anschrift Beladestelle (2. Zeile)", ""],
  ["beladestelle.plz", "Postleitzahl Beladestelle", "12345"],
  ["beladestelle.ort", "Ort Beladestelle", "Musterstadt"],
  [
    "beladestelle.istFirma",
    "Falls dies eine Firmenanschrift ist 1, sonst 0",
    "0",
  ],
  ["beladestelle.firma", "Firmenname", ""],
  ["beladestelle.telefon", "Telefonnr. Beladestelle", "01234 567890"],
  [
    "beladestelle.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
  ["beladestelle.etage", "Etage der Beladestelle", "0"],
  [
    "beladestelleEinzeilig",
    "Einzeilige Anschrift Beladestelle",
    "Erika Mustermann, Musterstraße 1, 12345 Musterstadt",
  ],
  [
    "entladestelle.anrede",
    "Anrede Entladestelle (Firmenname bei Firmen)",
    "Frau",
  ],
  [
    "entladestelle.name",
    "Vollständiger Name Entladestelle",
    "Erika Mustermann",
  ],
  ["entladestelle.vorname", "Vorname Entladestelle", "Erika"],
  ["entladestelle.nachname", "Nachname Entladestelle", "Mustermann"],
  ["entladestelle.anschrift", "Anschrift Entladestelle", "Musterstraße 1"],
  ["entladestelle.anschrift2", "Anschrift Entladestelle (2. Zeile)", ""],
  ["entladestelle.plz", "Postleitzahl Entladestelle", "12345"],
  ["entladestelle.ort", "Ort Entladestelle", "Musterstadt"],
  [
    "entladestelle.istFirma",
    "Falls dies eine Firmenanschrift ist 1, sonst 0",
    "0",
  ],
  ["entladestelle.firma", "Firmenname", ""],
  ["entladestelle.telefon", "Telefonnr. Entladestelle", "01234 567890"],
  [
    "entladestelle.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
  ["entladestelle.etage", "Etage der Entladestelle", "2"],
  [
    "entladestelleEinzeilig",
    "Einzeilige Anschrift Entladestelle",
    "Erika Mustermann, Musterstraße 1, 12345 Musterstadt",
  ],
  ["umfang", "Gesamtvolumen in m³", "47,2"],
  ["entfernung", "Gesamtentfernung in km", "54"],
  [
    "position.beladungTransport",
    "Preis für Be- und Entladung sowie Transport",
    "1811,48",
  ],
  ["position.maut", "Straßenbenutzungsgebühr (Maut)", "20,60"],
  ["position.verpackungsarbeiten", "Verpackungsarbeiten", "144,50"],
  ["position.kleinteilepauschale", "Kleinteilepauschale", "15,00"],
  ["position.halteverbotszonen", "Preis für HVZ zzgl. Gebühren", "43,80"],
  ["position.lagerkosten", "Lagerkosten", "538,30"],
  [
    "position.besondererAufwand",
    "Besonderer Aufwand (Klavier, Tresor usw.)",
    "0,00",
  ],
  ["position.packmaterial1Menge", "Packmaterial 1 Menge", "50"],
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `position.packmaterial${i + 1}Menge`,
      "Packmaterial (Menge)",
      i === 0 ? "75" : "",
      {
        hide: i > 0,
        displayName:
          "{position.packmaterial1Menge} bis {position.packmaterial12Menge}",
      },
    ]) as Placeholders),
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `position.packmaterial${i + 1}Preis`,
      "Packmaterial (Preis)",
      i === 0 ? "69,69" : "",
      {
        hide: i > 0,
        displayName:
          "{position.packmaterial1Menge} bis {position.packmaterial12Menge}",
      },
    ]) as Placeholders),
  ["aussenaufzug", "Falls ein Außenaufzug gestellt wird 1, sonst 0", "1"],
  ["entsorgung", "Falls Entsorgung gewünscht ist 1, sonst 0", "0"],
  ["entsorgungKosten", "Entsorgungskosten (nach Aufwand in €/t)", "54,00"],
  [
    "gesamtkostenOhneKundenrabatt",
    "Voraussichtliche Gesamtkosten (ohne Kundenrabatt)",
    "2738,24",
  ],
  ["gesamtkosten", "Voraussichtliche Gesamtkosten", "2738,24"],
  ["mehrwertsteuer", "Mehrwertsteuer", "520,27"],
  ["mehrwertsteuersatz", "Mehrwertsteuersatz", "19"],
  ["versicherungsbeitrag", "Versicherungsbeitrag für Neuwertversicherung", "0"],
  ["neuwert", "Neuwert", "0"],
  ["barzahlung", "Bei Barzahlung 1, sonst 0", "1"],
  ["beladung.datum", "Datum der Beladung", "25.02.2021"],
  ["beladung.alternativDatum", "", ""],
  [
    "beladung.zeitraum",
    "Geplanter Zeitraum für die Beladung",
    "7:00 bis 7:30 Uhr",
  ],
  ["entladung.datum", "Datum der Entladung", "25.03.2021"],
  ["entladung.alternativDatum", "", ""],
  [
    "entladung.zeitraum",
    "Geplanter Zeitraum für die Entladung",
    "7:00 bis 7:30 Uhr",
  ],
  [
    "packmaterialVorab",
    "Falls Packmaterial vorab geliefert wird 1, sonst 0",
    "1",
  ],
  [
    "packmaterialAnlieferungDatum",
    "Datum für Anlieferung des Packmaterials",
    "20.12.2020",
  ],
  ["angebotsdatum", "Angebotsdatum", "06.12.2020"],
  ["besonderheiten", "Besonderheiten (Freifeld)", "Dies ist ein Beispiel."],
  ["kundenrabatt", "Kundenrabatt in Euro (positiv)", "0,00"],
  ["kundenrabattProzent", "Kundenrabatt in Prozent vom Netto-Preis", "0,00"],
];

export const offerConfirmation: Placeholders = [
  ...customer,
  ["beladestelle", "Beladestelle (Ort)", "Musterstadt"],
  ["entladestelle", "Entladestelle (Ort)", "Musterhausen"],
  ["datum", "Geplantes Datum für die Beladung", "01.01.2021"],
  ["zeitraum", "Geplanter Zeitraum für die Beladung", "7:00 bis 7:30 Uhr"],
  ["barzahlung", "Bei Barzahlung 1, sonst 0", "1"],
  ["unterschrift", "Unterschrift des Beraters", "ppa. M. Mustermann"],
  [
    "packmaterialAnlieferungDatum",
    "Datum für Anlieferung des Packmaterials",
    "20.12.2020",
  ],
];

export const workInstructions: Placeholders = [
  ...customer,
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `vorabMenge${i + 1}`,
      "Vorab angeliefertes Packmaterial (Menge)",
      i === 0 ? "10 kg" : "",
      { hide: i > 0, displayName: "{vorabMenge1} bis {vorabMenge12}" },
    ]) as Placeholders),
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `vorabName${i + 1}`,
      `Vorab angeliefertes Packmaterial (Bezeichnung)`,
      i === 0 ? "Packpapier" : "",
      { hide: i > 0, displayName: "{vorabName1} bis {vorabName12}" },
    ]) as Placeholders),
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `beginnMenge${i + 1}`,
      "Mitzunehmendes Packmaterial (Menge)",
      i === 0 ? "1 Stk." : "",
      { hide: i > 0, displayName: "{beginnMenge1} bis {beginnMenge12}" },
    ]) as Placeholders),
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `beginnName${i + 1}`,
      `Mitzunehmendes Packmaterial (Bezeichnung)`,
      i === 0 ? "Fernsehkiste" : "",
      { hide: i > 0, displayName: "{beginnName1} bis {beginnName12}" },
    ]) as Placeholders),
  ["montage", "Falls Montagearbeiten durchgeführt werden 1, sonst 0", "1"],
  ["demontage", "Falls Demontagearbeiten durchgeführt werden 1, sonst 0", "0"],
  ["aussenaufzug", "Falls der Außenaufzug benötigt wird 1, sonst 0", "0"],
  [
    "nurMoebelwagen",
    "Falls nur der Möbelwagen eingesetzt wird 1, sonst 0",
    "0",
  ],
  [
    "ablaufbeschreibungBeladestelle",
    "Ablaufbeschreibung an der Beladestelle (Freitextfeld)",
    "",
  ],
  [
    "montagearbeitenBeladestelle",
    "Montagearbeiten an der Beladestelle (Freitextfeld)",
    "",
  ],
  [
    "halteverbotszoneBeladestelle",
    "Halteverbotszone an der Beladestelle (Freitextfeld)",
    "",
  ],
  [
    "ablaufbeschreibungEntladestelle",
    "Ablaufbeschreibung an der Entladestelle (Freitextfeld)",
    "",
  ],
  [
    "montagearbeitenEntladestelle",
    "Montagearbeiten an der Entladestelle (Freitextfeld)",
    "",
  ],
  [
    "halteverbotszoneEntladestelle",
    "Halteverbotszone an der Entladestelle (Freitextfeld)",
    "",
  ],
];

export const packingMaterialDelivery: Placeholders = [
  [
    "beladestelle.anrede",
    "Anrede Beladestelle (Firmenname bei Firmen)",
    "Frau",
  ],
  ["beladestelle.name", "Vollständiger Name Beladestelle", "Erika Mustermann"],
  ["beladestelle.vorname", "Vorname Beladestelle", "Erika"],
  ["beladestelle.nachname", "Nachname Beladestelle", "Mustermann"],
  ["beladestelle.anschrift", "Anschrift Beladestelle", "Musterstraße 1"],
  ["beladestelle.anschrift2", "Anschrift Beladestelle (2. Zeile)", ""],
  ["beladestelle.plz", "Postleitzahl Beladestelle", "12345"],
  ["beladestelle.ort", "Ort Beladestelle", "Musterstadt"],
  [
    "beladestelle.istFirma",
    "Falls dies eine Firmenanschrift ist 1, sonst 0",
    "0",
  ],
  ["beladestelle.firma", "Firmenname", ""],
  ["beladestelle.telefon", "Telefonnr. des Kunden", "01234 567890"],
  [
    "beladestelle.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
  ["datum", "Geplantes Datum für den Umzug", "01.01.2021"],
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `vorabMenge${i + 1}`,
      "Vorab angeliefertes Packmaterial (Menge)",
      i === 0 ? "10 kg" : "",
      { hide: i > 0, displayName: "{vorabMenge1} bis {vorabMenge12}" },
    ]) as Placeholders),
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `vorabName${i + 1}`,
      `Vorab angeliefertes Packmaterial (Bezeichnung)`,
      i === 0 ? "Packpapier" : "",
      { hide: i > 0, displayName: "{vorabName1} bis {vorabName12}" },
    ]) as Placeholders),
];

export const packingMaterialPickup: Placeholders = [
  [
    "entladestelle.anrede",
    "Anrede Entladestelle (Firmenname bei Firmen)",
    "Frau",
  ],
  [
    "entladestelle.name",
    "Vollständiger Name Entladestelle",
    "Erika Mustermann",
  ],
  ["entladestelle.vorname", "Vorname Entladestelle", "Erika"],
  ["entladestelle.nachname", "Nachname Entladestelle", "Mustermann"],
  ["entladestelle.anschrift", "Anschrift Entladestelle", "Musterstraße 1"],
  ["entladestelle.anschrift2", "Anschrift Entladestelle (2. Zeile)", ""],
  ["entladestelle.plz", "Postleitzahl Entladestelle", "12345"],
  ["entladestelle.ort", "Ort Entladestelle", "Musterstadt"],
  [
    "entladestelle.istFirma",
    "Falls dies eine Firmenanschrift ist 1, sonst 0",
    "0",
  ],
  ["entladestelle.firma", "Firmenname", ""],
  ["entladestelle.telefon", "Telefonnr. des Kunden", "01234 567890"],
  [
    "entladestelle.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `packmaterial${i + 1}`,
      `Packmaterial (Bezeichnung)`,
      i === 0 ? "Fernsehkiste" : "",
      { hide: i > 0, displayName: "{packmaterial1} bis {packmaterial12}" },
    ]) as Placeholders),
];

export const feedbackLetter: Placeholders = [
  ["rechnung.anrede", "Anrede des Kunden (Firmenname bei Firmen)", "Frau"],
  ["rechnung.name", "Vollständiger Name des Kunden", "Erika Mustermann"],
  ["rechnung.vorname", "Vorname des Kunden", "Erika"],
  ["rechnung.nachname", "Nachname des Kunden", "Mustermann"],
  ["rechnung.anschrift", "Anschrift des Kunden", "Musterstraße 1"],
  ["rechnung.anschrift2", "Anschrift des Kunden (2. Zeile)", ""],
  ["rechnung.plz", "Postleitzahl des Kunden", "12345"],
  ["rechnung.ort", "Ort des Kunden", "Musterstadt"],
  ["rechnung.istFirma", "Falls dies eine Firmenanschrift ist 1, sonst 0", "0"],
  ["rechnung.firma", "Firmenname", ""],
  ["rechnung.telefon", "Telefonnr. des Kunden", "01234 567890"],
  [
    "rechnung.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
  ["unterschrift", "Unterschrift des Beraters", "ppa. M. Mustermann"],
];

export const invoice: Placeholders = [
  ["rechnung.anrede", "Anrede des Kunden (Firmenname bei Firmen)", "Frau"],
  ["rechnung.name", "Vollständiger Name des Kunden", "Erika Mustermann"],
  ["rechnung.vorname", "Vorname des Kunden", "Erika"],
  ["rechnung.nachname", "Nachname des Kunden", "Mustermann"],
  ["rechnung.anschrift", "Anschrift des Kunden", "Musterstraße 1"],
  ["rechnung.anschrift2", "Anschrift des Kunden (2. Zeile)", ""],
  ["rechnung.plz", "Postleitzahl des Kunden", "12345"],
  ["rechnung.ort", "Ort des Kunden", "Musterstadt"],
  ["rechnung.istFirma", "Falls dies eine Firmenanschrift ist 1, sonst 0", "0"],
  ["rechnung.firma", "Firmenname", ""],
  ["rechnung.telefon", "Telefonnr. des Kunden", "01234 567890"],
  [
    "rechnung.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
  ["umzugsdatum", "Umzugsdatum", "01.01.2021"],
  ["rechnungsnummer", "Rechnungsnummer", "202100001"],
  ["rechnungsdatum", "Rechnungsnummer", "03.01.2021"],
  ["sendungsnummer", "Sendungsnummer", ""],
  ["angebotsdatum", "Angebotsdatum", "06.12.2020"],
  [
    "preisUmzug",
    "Preis für den Umzug (ohne zusätzliche Leistungen)",
    "3047,10",
  ],
  ["summe", "Summe aller Positionen", "3086,700"],
  ["mehrwertsteuer", "Mehrwertsteuer", "586,47"],
  ["mehrwertsteuersatz", "Mehrwertsteuersatz", "19"],
  ["versicherungsbeitrag", "Versicherungsbeitrag für Neuwertversicherung", "0"],
  ["barzahlung", "Bei Barzahlung 1, sonst 0", "1"],
  ["faltkartonsAbholen", "Falls Faltkartons abgeholt werden 1, sonst 0", "1"],
  ...(Array(10)
    .fill(0)
    .map((_, i) => [
      `position${i + 1}Name`,
      "Zusätzliche Leistung (Name)",
      i === 0 ? "Entsorgungskosten" : "",
      {
        hide: i > 0,
        displayName: "{position1Name} bis {position10Name}",
      },
    ]) as Placeholders),
  ...(Array(10)
    .fill(0)
    .map((_, i) => [
      `position${i + 1}Menge`,
      "Zusätzliche Leistung (Menge)",
      i === 0 ? "0,5" : "",
      {
        hide: i > 0,
        displayName: "{position1Menge} bis {position10Menge}",
      },
    ]) as Placeholders),
  ...(Array(10)
    .fill(0)
    .map((_, i) => [
      `position${i + 1}Einheit`,
      "Zusätzliche Leistung (Einheit)",
      i === 0 ? "t" : "",
      {
        hide: i > 0,
        displayName: "{position1Einheit} bis {position10Einheit}",
      },
    ]) as Placeholders),
  ...(Array(10)
    .fill(0)
    .map((_, i) => [
      `position${i + 1}Stkpreis`,
      "Zusätzliche Leistung (Stückpreis)",
      i === 0 ? "183 €/t" : "",
      {
        hide: i > 0,
        displayName: "{position1Stkpreis} bis {position10Stkpreis}",
      },
    ]) as Placeholders),
  ...(Array(10)
    .fill(0)
    .map((_, i) => [
      `position${i + 1}Preis`,
      "Zusätzliche Leistung (Preis)",
      i === 0 ? "91,50" : "",
      {
        hide: i > 0,
        displayName: "{position1Preis} bis {position10Preis}",
      },
    ]) as Placeholders),
];

export const workSlip: Placeholders = [
  ["rechnung.anrede", "Anrede des Kunden (Firmenname bei Firmen)", "Frau"],
  ["rechnung.name", "Vollständiger Name des Kunden", "Erika Mustermann"],
  ["rechnung.vorname", "Vorname des Kunden", "Erika"],
  ["rechnung.nachname", "Nachname des Kunden", "Mustermann"],
  ["rechnung.anschrift", "Anschrift des Kunden", "Musterstraße 1"],
  ["rechnung.anschrift2", "Anschrift des Kunden (2. Zeile)", ""],
  ["rechnung.plz", "Postleitzahl des Kunden", "12345"],
  ["rechnung.ort", "Ort des Kunden", "Musterstadt"],
  ["rechnung.istFirma", "Falls dies eine Firmenanschrift ist 1, sonst 0", "0"],
  ["rechnung.firma", "Firmenname", ""],
  ["rechnung.telefon", "Telefonnr. des Kunden", "01234 567890"],
  [
    "rechnung.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
  [
    "beladestelle.anrede",
    "Anrede Entladestelle (Firmenname bei Firmen)",
    "Frau",
  ],
  ["beladestelle.name", "Vollständiger Name des Kunden", "Erika Mustermann"],
  ["beladestelle.vorname", "Vorname des Kunden", "Erika"],
  ["beladestelle.nachname", "Nachname des Kunden", "Mustermann"],
  ["beladestelle.anschrift", "Anschrift des Kunden", "Musterstraße 1"],
  ["beladestelle.anschrift2", "Anschrift des Kunden (2. Zeile)", ""],
  ["beladestelle.plz", "Postleitzahl des Kunden", "12345"],
  ["beladestelle.ort", "Ort des Kunden", "Musterstadt"],
  [
    "beladestelle.istFirma",
    "Falls dies eine Firmenanschrift ist 1, sonst 0",
    "0",
  ],
  ["beladestelle.firma", "Firmenname", ""],
  ["beladestelle.telefon", "Telefonnr. des Kunden", "01234 567890"],
  [
    "beladestelle.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
  ["beladestelle.etage", "Etage der Beladestelle", "2"],
  [
    "entladestelle.anrede",
    "Anrede Entladestelle (Firmenname bei Firmen)",
    "Frau",
  ],
  ["entladestelle.name", "Vollständiger Name des Kunden", "Erika Mustermann"],
  ["entladestelle.vorname", "Vorname des Kunden", "Erika"],
  ["entladestelle.nachname", "Nachname des Kunden", "Mustermann"],
  ["entladestelle.anschrift", "Anschrift des Kunden", "Musterstraße 1"],
  ["entladestelle.anschrift2", "Anschrift des Kunden (2. Zeile)", ""],
  ["entladestelle.plz", "Postleitzahl des Kunden", "12345"],
  ["entladestelle.ort", "Ort des Kunden", "Musterstadt"],
  [
    "entladestelle.istFirma",
    "Falls dies eine Firmenanschrift ist 1, sonst 0",
    "0",
  ],
  ["entladestelle.firma", "Firmenname", ""],
  ["entladestelle.telefon", "Telefonnr. des Kunden", "01234 567890"],
  [
    "entladestelle.anredeBrief",
    "Vollständige Anrede für Briefe",
    "Sehr geehrte Frau Mustermann,",
  ],
  ["entladestelle.etage", "Etage der Entladestelle", "2"],
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `packmaterial${i + 1}`,
      `Packmaterial (Bezeichnung)`,
      i === 0 ? "Fernsehkiste" : "",
      { hide: i > 0, displayName: "{packmaterial1} bis {packmaterial12}" },
    ]) as Placeholders),
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `vorabMenge${i + 1}`,
      "Vorab angeliefertes Packmaterial (Menge)",
      i === 0 ? "10 kg" : "",
      { hide: i > 0, displayName: "{vorabMenge1} bis {vorabMenge12}" },
    ]) as Placeholders),
  ...(Array(12)
    .fill(0)
    .map((_, i) => [
      `beginnMenge${i + 1}`,
      "Mitzunehmendes Packmaterial (Menge)",
      i === 0 ? "1 Stk." : "",
      { hide: i > 0, displayName: "{beginnMenge1} bis {beginnMenge12}" },
    ]) as Placeholders),
  ["datumBeladung", "Datum der Beladung", "25.02.2021"],
  ["datumEntladung", "Datum der Entladung", "25.03.2021"],
];
