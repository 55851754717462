import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { TooltipIconButton } from "@wa/werkstoff-core";
import { useFormik } from "formik";
import { gql } from "graphql.macro";
import { useSnackbar } from "material-ui-snackbar-provider";
import { Plus } from "mdi-material-ui";
import React, { useCallback, useState } from "react";
import ActionBar from "../../components/ActionBar";
import {
  CreateInvoiceItemDocument,
  GetInvoiceItemsDocument,
  RemoveInvoiceItemDocument,
  UpdateInvoiceItemDocument,
} from "../../generated/graphql";
import InvoiceItemForm, {
  InvoiceItemFormValue,
} from "./components/InvoiceItemForm";
import { getUnit } from "./units";

gql`
  fragment FullInvoiceItem on InvoiceItem {
    id
    name
    unit
    price
  }
`;

gql`
  query GetInvoiceItems {
    invoiceItems {
      ...FullInvoiceItem
    }
  }
`;

gql`
  mutation CreateInvoiceItem($invoiceItem: InvoiceItemInput!) {
    createInvoiceItem(invoiceItem: $invoiceItem) {
      ...FullInvoiceItem
    }
  }
`;

gql`
  mutation UpdateInvoiceItem($id: ID!, $invoiceItem: InvoiceItemInput!) {
    updateInvoiceItem(id: $id, invoiceItem: $invoiceItem) {
      ...FullInvoiceItem
    }
  }
`;

gql`
  mutation RemoveInvoiceItem($id: ID!) {
    removeInvoiceItem(id: $id) {
      id
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: "pointer",
    },
    placeholder: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
    removeDialogButton: {
      color: theme.palette.error.dark,
      marginRight: "auto",
    },
    moreItems: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      "& > .MuiFormControlLabel-label": {
        ...theme.typography.body2,
      },
    },
  });

function RoomsContainer({ classes }: WithStyles<typeof styles>) {
  const snackbar = useSnackbar();

  const { data } = useQuery(GetInvoiceItemsDocument);

  const [createInvoiceItem] = useMutation(CreateInvoiceItemDocument, {
    refetchQueries: [{ query: GetInvoiceItemsDocument }],
  });
  const [addAnotherItem, setAddAnotherItem] = useState(false);
  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const handleCreateInvoiceItem = useCallback(
    async (value: InvoiceItemFormValue) => {
      try {
        await createInvoiceItem({
          variables: {
            invoiceItem: {
              name: value.name,
              unit: value.unit === "" ? null : value.unit,
              price: value.price || null,
            },
          },
        });
        snackbar.showMessage(
          `Die Rechnungsposition "${value.name}" wurde hinzugefügt.`
        );
      } catch (e) {
        snackbar.showMessage(
          "Die Rechnungsposition konnte nicht hinzugefügt werden."
        );
        console.error("Could not create invoice item", e);
      }
    },
    [snackbar, createInvoiceItem]
  );
  const formikNewItem = useFormik<InvoiceItemFormValue>({
    initialValues: {
      name: "",
      unit: "",
    },
    onSubmit: async (value) => {
      await handleCreateInvoiceItem(value);
      if (addAnotherItem) {
        formikNewItem.resetForm();
      } else {
        setShowAddItemDialog(false);
      }
    },
  });

  const [updateInvoiceItem] = useMutation(UpdateInvoiceItemDocument, {
    refetchQueries: [{ query: GetInvoiceItemsDocument }],
  });
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const [showEditItemDialog, setShowEditItemDialog] = useState(false);
  const formikEditItem = useFormik<InvoiceItemFormValue & { id?: string }>({
    initialValues: { name: "", unit: "" },
    onSubmit: async (value) => {
      try {
        await updateInvoiceItem({
          variables: {
            id: editItemId!,
            invoiceItem: {
              name: value.name,
              price: value.price || null,
              unit: value.unit === "" ? null : value.unit,
            },
          },
        });
        setShowEditItemDialog(false);
        setEditItemId(null);
        snackbar.showMessage(
          `Die Rechnungsposition "${value.name}" wurde aktualisiert.`
        );
      } catch (e) {
        snackbar.showMessage(
          "Die Rechnungsposition konnte nicht aktualisiert werden."
        );
      }
    },
  });

  const handleShowItem = useCallback(
    (e) => {
      const id = e.currentTarget.dataset.itemId;
      console.log({ id });
      setEditItemId(id);
      setShowEditItemDialog(true);
      formikEditItem.resetForm({
        values: data?.invoiceItems.find((m) => m.id === id),
      });
    },
    [formikEditItem, data]
  );

  const [removeInvoiceItem] = useMutation(RemoveInvoiceItemDocument, {
    refetchQueries: [{ query: GetInvoiceItemsDocument }],
  });
  const handleDeleteInvoiceItem = useCallback(
    async (id: string) => {
      const item = data?.invoiceItems.find((m) => m.id === id);
      if (!item) return;
      try {
        await removeInvoiceItem({ variables: { id } });
        setShowEditItemDialog(false);
        setEditItemId(null);
        snackbar.showMessage(
          `Die Rechnungsposition "${item.name}" wurde entfernt.`,
          "Rückgängig",
          () => handleCreateInvoiceItem(item)
        );
      } catch (e) {
        snackbar.showMessage(
          "Die Rechnungsposition konnte nicht entfernt werden."
        );
      }
    },
    [data, snackbar, handleCreateInvoiceItem, removeInvoiceItem]
  );

  return (
    <>
      <ActionBar title="Rechnungspositionen">
        <TooltipIconButton
          tooltip="Rechnungsposition hinzufügen"
          onClick={() => {
            formikNewItem.resetForm();
            setShowAddItemDialog(true);
          }}
        >
          <Plus />
        </TooltipIconButton>
      </ActionBar>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Bezeichnung</TableCell>
              <TableCell>Einheit</TableCell>
              <TableCell>Preis</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.invoiceItems.map((item) => (
              <TableRow
                key={item.id}
                onClick={handleShowItem}
                hover
                data-item-id={item.id}
                className={classes.tableRow}
              >
                <TableCell>{item.name}</TableCell>
                <TableCell>{getUnit(item.unit)}</TableCell>
                <TableCell>
                  {item.price && item.unit
                    ? `${item.price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} €/${getUnit(item.unit)}`
                    : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={showAddItemDialog}
        onClose={() => setShowAddItemDialog(false)}
        disableBackdropClick
        fullWidth
      >
        <DialogTitle>Rechnungsposition hinzufügen</DialogTitle>
        <DialogContent>
          <InvoiceItemForm formikConfig={formikNewItem} />
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={addAnotherItem}
                onChange={(e) => setAddAnotherItem(e.target.checked)}
                name="addAnotherItem"
                color="primary"
              />
            }
            label="Weitere Rechnungsposition hinzufügen"
            className={classes.moreItems}
          />
          <Button onClick={() => setShowAddItemDialog(false)}>Abbrechen</Button>
          <Button color="primary" onClick={formikNewItem.submitForm}>
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showEditItemDialog}
        onClose={() => setShowEditItemDialog(false)}
        disableBackdropClick
        fullWidth
      >
        <DialogTitle>Rechnungsposition bearbeiten</DialogTitle>
        <DialogContent>
          <InvoiceItemForm formikConfig={formikEditItem} />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.removeDialogButton}
            onClick={() => handleDeleteInvoiceItem(editItemId!)}
          >
            Entfernen
          </Button>
          <Button onClick={() => setShowEditItemDialog(false)}>
            Abbrechen
          </Button>
          <Button color="primary" onClick={formikEditItem.submitForm}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(RoomsContainer);
