import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { TooltipIconButton, useDialog } from "@wa/werkstoff-core";
import { useFormik } from "formik";
import { gql } from "graphql.macro";
import { useSnackbar } from "material-ui-snackbar-provider";
import { Plus } from "mdi-material-ui";
import React, { useCallback, useState } from "react";
import ActionBar from "../../components/ActionBar";
import {
  CreateUserDocument,
  GetUsersDocument,
  UpdateUserDocument,
  RemoveUserDocument,
} from "../../generated/graphql";
import useDialogOpen from "../../hooks/useDialogOpen";
import UserForm, { UserFormValue } from "./components/UserForm";

gql`
  query GetUsers {
    users {
      id
      username
      fullName
      signaturePrefix
    }
  }
`;

gql`
  mutation CreateUser($user: CreateUserInput!) {
    createUser(user: $user) {
      id
    }
  }
`;

gql`
  mutation UpdateUser($id: ID!, $user: UpdateUserInput!) {
    updateUser(id: $id, user: $user) {
      id
    }
  }
`;

gql`
  mutation RemoveUser($id: ID!) {
    removeUser(id: $id) {
      id
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: "pointer",
    },
    placeholder: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
    removeDialogButton: {
      color: theme.palette.error.dark,
      marginRight: "auto",
    },
    moreItems: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      "& > .MuiFormControlLabel-label": {
        ...theme.typography.body2,
      },
    },
  });

function Users({ classes }: WithStyles<typeof styles>) {
  const snackbar = useSnackbar();

  const { data } = useQuery(GetUsersDocument);

  const [createUser] = useMutation(CreateUserDocument, {
    refetchQueries: [{ query: GetUsersDocument }],
  });
  const [addAnotherItem, setAddAnotherItem] = useState(false);
  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const handleCreateUser = useCallback(
    async (value: UserFormValue) => {
      try {
        await createUser({
          variables: {
            user: {
              ...value,
              password: value.password!, // checked above
            },
          },
        });
        snackbar.showMessage(
          `Der Benutzer "${value.username}" wurde hinzugefügt.`
        );
      } catch (e) {
        snackbar.showMessage("Der Benutzer konnte nicht hinzugefügt werden.");
        console.error("Could not create user", e);
      }
    },
    [snackbar, createUser]
  );
  const formikNewItem = useFormik<UserFormValue>({
    initialValues: {
      username: "",
      fullName: "",
      signaturePrefix: "",
      password: "",
    },
    onSubmit: async (value) => {
      if (!value.password) {
        snackbar.showMessage("Es muss ein Passwort vergeben werden.");
        return;
      }
      await handleCreateUser(value);
      if (addAnotherItem) {
        formikNewItem.resetForm();
      } else {
        setShowAddItemDialog(false);
      }
    },
  });

  const [updateUser] = useMutation(UpdateUserDocument, {
    refetchQueries: [{ query: GetUsersDocument }],
  });
  const editItemDialog = useDialogOpen<string>();
  const formikEditItem = useFormik<UserFormValue & { id?: string }>({
    initialValues: {
      username: "",
      fullName: "",
      signaturePrefix: "",
      password: null,
    },
    onSubmit: async ({ id, ...value }) => {
      try {
        await updateUser({
          variables: {
            id: editItemDialog.data!,
            user: {
              ...value,
              password: value.password || null,
            },
          },
        });
        editItemDialog.handleClose();
        snackbar.showMessage(
          `Der Benutzer "${value.username}" wurde aktualisiert.`
        );
      } catch (e) {
        snackbar.showMessage("Der Benutzer konnte nicht aktualisiert werden.");
      }
    },
  });

  const handleShowItem = useCallback(
    (e) => {
      const id = e.currentTarget.dataset.itemId;
      editItemDialog.handleOpen(id);
      formikEditItem.resetForm({
        values: data?.users.find((m) => m.id === id),
      });
    },
    [formikEditItem, data, editItemDialog]
  );

  const showDialog = useDialog();

  const [removeUser] = useMutation(RemoveUserDocument, {
    refetchQueries: [{ query: GetUsersDocument }],
  });
  const handleDeleteUser = useCallback(
    async (id: string) => {
      const item = data?.users.find((m) => m.id === id);
      if (!item) return;
      showDialog({
        title: "Benutzer löschen",
        message: `Möchten Sie den Benutzer "${item.username}" wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.`,
        actions: [
          { label: "Abbrechen", action: "close" },
          {
            action: async () => {
              try {
                await removeUser({ variables: { id } });
                editItemDialog.handleClose();
                snackbar.showMessage(
                  `Der Benutzer "${item.username}" wurde gelöscht.`
                );
              } catch (e) {
                snackbar.showMessage(
                  "Der Benutzer konnte nicht gelöscht werden."
                );
              }
            },
            label: "Benutzer löschen",
            color: "error",
          },
        ],
      });
    },
    [data, snackbar, removeUser, showDialog, editItemDialog]
  );

  return (
    <>
      <ActionBar title="Benutzer">
        <TooltipIconButton
          tooltip="Benutzer hinzufügen"
          onClick={() => {
            formikNewItem.resetForm();
            setShowAddItemDialog(true);
          }}
        >
          <Plus />
        </TooltipIconButton>
      </ActionBar>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Benutzername</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.users
              .filter((user) => user.username !== "backend")
              .map((item) => (
                <TableRow
                  key={item.id}
                  onClick={handleShowItem}
                  hover
                  data-item-id={item.id}
                  className={classes.tableRow}
                >
                  <TableCell>{item.username}</TableCell>
                  <TableCell>{item.fullName}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={showAddItemDialog}
        onClose={() => setShowAddItemDialog(false)}
        disableBackdropClick
        fullWidth
      >
        <DialogTitle>Benutzer hinzufügen</DialogTitle>
        <DialogContent>
          <UserForm formikConfig={formikNewItem} isNew />
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={addAnotherItem}
                onChange={(e) => setAddAnotherItem(e.target.checked)}
                name="addAnotherItem"
                color="primary"
              />
            }
            label="Weitere Benutzer hinzufügen"
            className={classes.moreItems}
          />
          <Button onClick={() => setShowAddItemDialog(false)}>Abbrechen</Button>
          <Button color="primary" onClick={formikNewItem.submitForm}>
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editItemDialog.open}
        onClose={editItemDialog.handleClose}
        disableBackdropClick
        fullWidth
      >
        <DialogTitle>Benutzer bearbeiten</DialogTitle>
        <DialogContent>
          <UserForm formikConfig={formikEditItem} />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.removeDialogButton}
            onClick={() => handleDeleteUser(editItemDialog.data!)}
          >
            Löschen
          </Button>
          <Button onClick={editItemDialog.handleClose}>Abbrechen</Button>
          <Button color="primary" onClick={formikEditItem.submitForm}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(Users);
