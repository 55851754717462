import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { gql } from "graphql.macro";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch, Prompt } from "react-router-dom";
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import RoomItemForm, { RoomItemFormValue } from "./components/RoomItemForm";
import { OverflowMenu, TooltipIconButton } from "@wa/werkstoff-core";
import { Check, Plus } from "mdi-material-ui";
import { useSnackbar } from "material-ui-snackbar-provider";
import {
  GetRoomDocument,
  GetRoomsDocument,
  CreateRoomDocument,
  UpdateRoomDocument,
  RemoveRoomDocument,
  PackingMaterialUnit,
  RoomItem,
} from "../../generated/graphql";
import ActionBar from "../../components/ActionBar";
import * as yup from "yup";

function formatWithUnit(
  name: string,
  unit: PackingMaterialUnit,
  amount: number
) {
  switch (unit) {
    case PackingMaterialUnit.Kilogram:
      return `${amount} kg ${name}`;
    case PackingMaterialUnit.Meter:
      return `${amount} m ${name}`;
    case PackingMaterialUnit.Piece:
    default:
      return `${amount}× ${name}`;
  }
}

gql`
  fragment RoomFragment on Room {
    id
    name
    items {
      id
      name
      size
      assemblyTime
      disassemblyTime
      packingMaterial {
        amount
        type {
          id
          name
          unit
        }
        inAdvance
      }
    }
  }
`;

gql`
  query GetRoom($id: ID!) {
    room(id: $id) {
      ...RoomFragment
    }
  }
`;

gql`
  mutation UpdateRoom($id: ID!, $room: RoomInput!) {
    updateRoom(id: $id, room: $room) {
      ...RoomFragment
    }
  }
`;

gql`
  mutation RemoveRoom($id: ID!) {
    removeRoom(id: $id) {
      id
    }
  }
`;

const itemSchema = yup.object().shape({
  name: yup
    .string()
    .min(1)
    .required(),
  size: yup
    .number()
    .min(0)
    .required(),
  assemblyTime: yup.number().nullable(),
  disassemblyTime: yup.number().nullable(),
  packingMaterial: yup
    .array(
      yup
        .object()
        .shape({
          type: yup
            .object()
            .shape({
              id: yup.string(),
              unit: yup.string(),
            })
            .required(),
          amount: yup
            .number()
            .min(0)
            .required(),
          inAdvance: yup.boolean().optional(),
        })
        .required()
    )
    .optional(),
});

const styles = (theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: "pointer",
    },
    removeDialogButton: {
      color: theme.palette.error.dark,
      marginRight: "auto",
    },
    placeholder: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
    moreItems: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      "& > .MuiFormControlLabel-label": {
        ...theme.typography.body2,
      },
    },
  });

function RoomContainer({ classes }: WithStyles<typeof styles>) {
  const snackbar = useSnackbar();
  const { push } = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const roomId = match.params.id;

  const { data } = useQuery(GetRoomDocument, {
    variables: { id: roomId },
    skip: roomId == null || roomId === "new",
  });

  const [room, setRoom] = useState<any>(data?.room);
  useEffect(() => {
    setRoom(data?.room);
  }, [data]);

  const [updateRoom] = useMutation(UpdateRoomDocument);

  const handleUpdateRoom = useCallback(async () => {
    try {
      await updateRoom({
        variables: {
          id: roomId,
          room: {
            name: room.name,
            items: room.items.map((item: RoomItem) => ({
              id: item.id.startsWith("new-") ? undefined : item.id, // update existing items instead of creating new items on every save
              name: item.name,
              size: item.size,
              assemblyTime: item.assemblyTime,
              disassemblyTime: item.disassemblyTime,
              packingMaterial: item.packingMaterial.map(
                ({ type, amount, inAdvance }) => ({
                  type: type.id,
                  amount,
                  inAdvance: inAdvance ?? true,
                })
              ),
            })),
          },
        },
      });
      snackbar.showMessage(`Der Raum "${room.name}" wurde gespeichert.`);
      push("/umzugsgut");
    } catch (e) {
      snackbar.showMessage(
        `Der Raum "${room.name}" konnte nicht gespeichert werden.`
      );
    }
  }, [snackbar, updateRoom, room, roomId, push]);

  const [addAnotherItem, setAddAnotherItem] = useState(false);
  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const formikNewItem = useFormik<RoomItemFormValue>({
    initialValues: {
      name: "",
      packingMaterial: [],
    },
    validationSchema: itemSchema,
    onSubmit: (value) => {
      setRoom((room: any) => ({
        ...room,
        items: [...(room?.items ?? []), { id: `new-${Date.now()}`, ...value }],
      }));
      if (addAnotherItem) {
        formikNewItem.resetForm();
      } else {
        setShowAddItemDialog(false);
      }
    },
  });

  const [showEditItemDialog, setShowEditItemDialog] = useState(false);
  const formikEditItem = useFormik<RoomItemFormValue & { id?: string }>({
    initialValues: { name: "", packingMaterial: [] },
    onSubmit: (value) => {
      setRoom((room: any) => ({
        ...room,
        items: room?.items.map((item: RoomItem) =>
          item.id === value.id ? value : item
        ),
      }));
      setShowEditItemDialog(false);
    },
    validationSchema: itemSchema,
  });

  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [newRoomName, setNewRoomName] = useState("");
  useEffect(() => {
    if (showRenameDialog) {
      setNewRoomName(room?.name);
    }
  }, [room, showRenameDialog]);

  const [removeRoom] = useMutation(RemoveRoomDocument, {
    refetchQueries: [{ query: GetRoomsDocument }],
  });
  const [createRoom] = useMutation(CreateRoomDocument, {
    refetchQueries: [{ query: GetRoomsDocument }],
  });
  const handleRemoveRoom = useCallback(async () => {
    const removedRoom = data?.room!;

    const restore = async () => {
      try {
        const { data } = await createRoom({
          variables: {
            room: {
              name: removedRoom.name,
              items: removedRoom.items.map(
                ({ id, __typename, packingMaterial, ...item }) => ({
                  ...item,
                  packingMaterial: packingMaterial.map((p) => ({
                    amount: p.amount,
                    type: p.type.id,
                    inAdvance: p.inAdvance,
                  })),
                })
              ),
            },
          },
        });
        push(`/umzugsgut/${data?.createRoom.id}`);
        snackbar.showMessage(
          `Der Raum "${removedRoom.name}" wurde wiederhergestellt.`
        );
      } catch (e) {
        snackbar.showMessage(
          `Der Raum "${removedRoom.name}" konnte nicht wiederhergestellt werden.`,
          "Wiederholen",
          restore
        );
      }
    };

    try {
      await removeRoom({ variables: { id: roomId } });
      snackbar.showMessage(
        `Der Raum "${removedRoom.name}" wurde gelöscht.`,
        "Rückgängig",
        restore
      );
      push("/umzugsgut");
    } catch (e) {
      snackbar.showMessage(
        `Der Raum "${removedRoom.name}" konnte nicht gelöscht werden.`
      );
    }
  }, [roomId, removeRoom, createRoom, snackbar, push, data]);

  return (
    <div>
      <Prompt
        when={room !== data?.room}
        message="Es gibt ungespeicherte Änderungen für diesen Raum. Wenn Sie diese Seite verlassen, werden die Änderungen verworfen."
      />
      <ActionBar
        title={room?.name}
        onBack={() => push("/umzugsgut")}
        backButtonLabel="Zurück zur Raumliste"
      >
        <TooltipIconButton
          tooltip="Gegenstand hinzufügen"
          onClick={() => {
            formikNewItem.resetForm();
            setShowAddItemDialog(true);
          }}
        >
          <Plus />
        </TooltipIconButton>
        <TooltipIconButton
          tooltip="Änderungen speichern"
          onClick={handleUpdateRoom}
        >
          <Check />
        </TooltipIconButton>
        <OverflowMenu>
          <MenuItem onClick={() => setShowRenameDialog(true)}>
            Raum umbenennen
          </MenuItem>
          <MenuItem onClick={handleRemoveRoom}>Raum löschen</MenuItem>
        </OverflowMenu>
      </ActionBar>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Gegenstand</TableCell>
              <TableCell>RE</TableCell>
              <TableCell>Montage (Std.)</TableCell>
              <TableCell>Demontage (Std.)</TableCell>
              <TableCell>Zusätzliches Packmaterial</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {room?.items?.map((item: RoomItem, i: number) => (
              <TableRow
                key={item.id ?? i}
                hover
                onClick={() => {
                  formikEditItem.resetForm({ values: item });
                  setShowEditItemDialog(true);
                }}
                className={classes.tableRow}
              >
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.size.toLocaleString()} RE</TableCell>
                <TableCell>{item.assemblyTime?.toLocaleString()}</TableCell>
                <TableCell>{item.disassemblyTime?.toLocaleString()}</TableCell>
                <TableCell>
                  {item.packingMaterial
                    .map((p) =>
                      formatWithUnit(p.type.name, p.type.unit, p.amount)
                    )
                    .join(", ")}
                </TableCell>
              </TableRow>
            ))}
            {room?.items.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} className={classes.placeholder}>
                  Sie haben für diesen Raum noch keine Gegenstände konfiguriert.
                  <br />
                  Klicken Sie auf &bdquo;+&ldquo;, um den ersten Gegenstand
                  hinzuzufügen.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={showAddItemDialog}
        onClose={() => setShowAddItemDialog(false)}
        disableBackdropClick
      >
        <DialogTitle>Gegenstand hinzufügen</DialogTitle>
        <DialogContent>
          <RoomItemForm formikConfig={formikNewItem} />
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={addAnotherItem}
                onChange={(e) => setAddAnotherItem(e.target.checked)}
                name="addAnotherItem"
                color="primary"
              />
            }
            label="Weitere Gegenstände hinzufügen"
            className={classes.moreItems}
          />
          <Button onClick={() => setShowAddItemDialog(false)}>Abbrechen</Button>
          <Button
            color="primary"
            onClick={formikNewItem.submitForm}
            disabled={!formikNewItem.dirty || !formikNewItem.isValid}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showEditItemDialog}
        onClose={() => setShowEditItemDialog(false)}
        disableBackdropClick
      >
        <DialogTitle>Gegenstand bearbeiten</DialogTitle>
        <DialogContent>
          <RoomItemForm formikConfig={formikEditItem} />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.removeDialogButton}
            onClick={() => {
              setRoom((room: any) => ({
                ...room,
                items: room.items.filter(
                  (item: RoomItem) => item.id !== formikEditItem.values.id
                ),
              }));
              setShowEditItemDialog(false);
            }}
          >
            Entfernen
          </Button>
          <Button onClick={() => setShowEditItemDialog(false)}>
            Abbrechen
          </Button>
          <Button
            color="primary"
            onClick={formikEditItem.submitForm}
            disabled={!formikEditItem.isValid}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showRenameDialog}
        onClose={() => setShowRenameDialog(false)}
        disableBackdropClick
        fullWidth
      >
        <DialogTitle>Raum umbenennen</DialogTitle>
        <DialogContent>
          <TextField
            label="Bezeichnung"
            value={newRoomName}
            onChange={(e) => setNewRoomName(e.target.value)}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRenameDialog(false)} tabIndex={1}>
            Abbrechen
          </Button>
          <Button
            color="primary"
            disabled={newRoomName.trim().length === 0}
            onClick={() => {
              setRoom({ ...room, name: newRoomName });
              setShowRenameDialog(false);
            }}
            tabIndex={0}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(RoomContainer);
