import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";

export default class EnhancedDateFnsUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: number | Date) {
    // Fr., 28. Sep. (like in Google Calendar)
    return format(date, "EEEEEE., dd. LLL.", { locale: deLocale });
  }
}
