export function setDeep(obj: any, key: string, value: any) {
  let current = obj;
  const path = key.split(".");
  for (let i = 0; i < path.length - 1; i++) {
    const element = path[i];
    if (!current[element]) {
      current[element] = {};
    }
    current = obj[element];
  }
  current[path[path.length - 1]] = value;
}
