import React, { useRef, useState } from "react";
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
  TextField,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { gql } from "graphql.macro";
import { useQuery } from "@apollo/client";
import { GetPackingMaterialsDocument } from "../../../generated/graphql";
import { TooltipIconButton } from "@wa/werkstoff-core";
import { Close, HelpCircleOutline, Plus } from "mdi-material-ui";
import { getUnit } from "../../packingMaterial/units";

gql`
  query GetPackingMaterials {
    packingMaterials {
      id
      name
      price
      unit
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formField: {
      marginBottom: theme.spacing(1),
    },
    fieldsRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
    subheader: {
      margin: theme.spacing(2, 0, 1),
    },
    tinyIconButton: {
      padding: theme.spacing(0.5),
      alignSelf: "center",
    },
    inAdvanceCheckbox: {
      ...theme.typography.body2,
      display: "inline-flex",
      alignItems: "center",
      "& svg": {
        color: theme.palette.action.active,
        cursor: "help",
        height: 18,
        width: 18,
        marginLeft: theme.spacing(0.5),
      },
    },
  });

export interface RoomItemFormValue {
  name: string;
  size?: number | null;
  assemblyTime?: number | null;
  disassemblyTime?: number | null;
  packingMaterial?: Array<{
    type?: {
      id: string;
      unit: string;
    };
    amount: number;
    inAdvance?: boolean;
  }>;
}

interface Props {
  formikConfig: FormikProps<RoomItemFormValue>;
}

function RoomItemForm({
  classes,
  formikConfig: formik,
}: Props & WithStyles<typeof styles>) {
  const { data: packingMaterials } = useQuery(GetPackingMaterialsDocument);

  const [newPackingMaterialId, setNewPackingMaterialId] = useState<string>();
  const [newPackingMaterialAmount, setNewPackingMaterialAmount] = useState<
    string
  >("");
  const [
    newPackingMaterialInAdvance,
    setNewPackingMaterialInAdvance,
  ] = useState(true);
  const newPackingMaterial = packingMaterials?.packingMaterials.find(
    (m) => m.id === newPackingMaterialId
  );
  const newPackingMaterialAmountRef = useRef<HTMLInputElement>();

  return (
    <div className={classes.root}>
      <TextField
        label="Bezeichnung"
        required
        className={classes.formField}
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        autoFocus
      />
      <TextField
        label="Größe"
        required
        className={classes.formField}
        InputProps={{
          endAdornment: <InputAdornment position="end">RE</InputAdornment>,
        }}
        type="number"
        name="size"
        value={formik.values.size ?? ""}
        onChange={formik.handleChange}
        error={formik.touched.size && Boolean(formik.errors.size)}
        helperText={formik.touched.size && formik.errors.size}
      />
      <div className={classes.fieldsRow}>
        <TextField
          label="Montagezeit"
          className={classes.formField}
          InputProps={{
            endAdornment: <InputAdornment position="end">Std.</InputAdornment>,
          }}
          type="number"
          name="assemblyTime"
          value={formik.values.assemblyTime ?? ""}
          onChange={formik.handleChange}
          error={
            formik.touched.assemblyTime && Boolean(formik.errors.assemblyTime)
          }
          helperText={formik.touched.assemblyTime && formik.errors.assemblyTime}
        />
        <TextField
          label="Demontagezeit"
          className={classes.formField}
          InputProps={{
            endAdornment: <InputAdornment position="end">Std.</InputAdornment>,
          }}
          type="number"
          name="disassemblyTime"
          value={formik.values.disassemblyTime ?? ""}
          onChange={formik.handleChange}
          error={
            formik.touched.disassemblyTime &&
            Boolean(formik.errors.disassemblyTime)
          }
          helperText={
            formik.touched.disassemblyTime && formik.errors.disassemblyTime
          }
        />
      </div>
      <Typography
        variant="caption"
        color="primary"
        className={classes.subheader}
      >
        Zusätzliches Packmaterial
      </Typography>
      {formik.values.packingMaterial?.map((material, i) => (
        <div className={classes.fieldsRow} key={i}>
          <FormControl fullWidth className={classes.formField}>
            <InputLabel>Packmaterial</InputLabel>
            <Select
              disabled={
                packingMaterials == null ||
                packingMaterials.packingMaterials.length === 0
              }
              value={material.type?.id}
              onChange={(e) => {
                formik.setFieldValue(
                  "packingMaterial",
                  formik.values.packingMaterial?.map((old, j) =>
                    j === i
                      ? {
                          ...old,
                          type: packingMaterials?.packingMaterials.find(
                            (p) => p.id === e.target.value
                          ),
                        }
                      : old
                  )
                );
              }}
            >
              {[...(packingMaterials?.packingMaterials ?? [])].map(
                (material) => (
                  <MenuItem key={material.id} value={material.id}>
                    {material.name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <TextField
            className={classes.formField}
            label="Menge"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getUnit(newPackingMaterial?.unit)}
                </InputAdornment>
              ),
            }}
            value={material.amount}
            onChange={(e) => {
              formik.setFieldValue(
                "packingMaterial",
                formik.values.packingMaterial?.map((old, j) =>
                  j === i
                    ? {
                        ...old,
                        amount: parseFloat(e.target.value),
                      }
                    : old
                )
              );
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={material.inAdvance !== false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "packingMaterial",
                    formik.values.packingMaterial?.map((old, j) =>
                      j === i
                        ? {
                            ...old,
                            inAdvance: e.target.checked,
                          }
                        : old
                    )
                  );
                }}
                name="addAnotherItem"
                color="primary"
              />
            }
            label={
              <>
                Vorab
                <Tooltip title="Gibt an, ob dieses Packmaterial standardmäßig vorab angeliefert werden soll (kann z.B. für Matratzenhüllen deaktiviert werden).">
                  <HelpCircleOutline color="inherit" />
                </Tooltip>
              </>
            }
            classes={{ label: classes.inAdvanceCheckbox }}
          />
          <TooltipIconButton
            className={classes.tinyIconButton}
            tooltip="Entfernen"
            onClick={() => {
              formik.setFieldValue(
                "packingMaterial",
                formik.values.packingMaterial?.filter((m, j) => j !== i)
              );
            }}
          >
            <Close />
          </TooltipIconButton>
        </div>
      ))}
      <div className={classes.fieldsRow}>
        <FormControl fullWidth>
          <InputLabel>Packmaterial</InputLabel>
          <Select
            disabled={
              packingMaterials == null ||
              packingMaterials.packingMaterials.length === 0
            }
            value={newPackingMaterialId ?? ""}
            onChange={(e) => {
              setNewPackingMaterialId(e.target.value as string);
              setTimeout(() => {
                newPackingMaterialAmountRef.current?.focus();
              }, 100);
            }}
          >
            {packingMaterials?.packingMaterials.map((material) => (
              <MenuItem key={material.id} value={material.id}>
                {material.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          inputRef={newPackingMaterialAmountRef}
          label="Menge"
          type="number"
          disabled={newPackingMaterial == null}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getUnit(newPackingMaterial?.unit)}
              </InputAdornment>
            ),
          }}
          value={newPackingMaterialAmount}
          onChange={(e) => setNewPackingMaterialAmount(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={newPackingMaterialInAdvance}
              onChange={(e) => setNewPackingMaterialInAdvance(e.target.checked)}
              name="addAnotherItem"
              color="primary"
            />
          }
          label={
            <>
              Vorab
              <Tooltip title="Gibt an, ob dieses Packmaterial standardmäßig vorab angeliefert werden soll (kann z.B. für Matratzenhüllen deaktiviert werden).">
                <HelpCircleOutline color="inherit" />
              </Tooltip>
            </>
          }
          classes={{ label: classes.inAdvanceCheckbox }}
        />
        <TooltipIconButton
          className={classes.tinyIconButton}
          tooltip="Hinzufügen"
          onClick={() => {
            formik.setFieldValue("packingMaterial", [
              ...(formik.values.packingMaterial || []),
              {
                amount: parseFloat(newPackingMaterialAmount!),
                type: newPackingMaterial,
                inAdvance: newPackingMaterialInAdvance,
              },
            ]);
            setNewPackingMaterialId("");
            setNewPackingMaterialAmount("");
            setNewPackingMaterialInAdvance(true);
          }}
          disabled={
            newPackingMaterial == null ||
            isNaN(parseFloat(newPackingMaterialAmount))
          }
        >
          <Plus />
        </TooltipIconButton>
      </div>
    </div>
  );
}

export default withStyles(styles)(RoomItemForm);
