import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ApolloProvider } from "@apollo/client";
import "typeface-roboto";
import "./App.css";
import apolloClient from "./modules/core/apolloClient";
import Application from "./modules/core/components/Application";
import AuthenticationContainer from "./modules/authentication/containers/AuthenticationContainer";
import AppFrame from "./modules/core/components/AppFrame";
import RoomsRoutes from "./modules/rooms/RoomsRoutes";
import PackingMaterial from "./modules/packingMaterial/PackingMaterial";
import CalculationParameters from "./modules/calculationParameters/CalculationParameters";
import InvoiceItems from "./modules/invoiceItems/InvoiceItems";
import Users from "./modules/users/Users";
import Templates from "./modules/templates/Templates";
import Contractors from "./modules/contractors/Contractors";

const history = createBrowserHistory();

export default function App() {
  return (
    <Router history={history}>
      <ApolloProvider client={apolloClient}>
        <Application>
          <AuthenticationContainer>
            <AppFrame>
              <Switch>
                <Route path="/umzugsgut" component={RoomsRoutes} />
                <Route path="/packmaterial" component={PackingMaterial} />
                <Route
                  path="/kalkulationssaetze"
                  component={CalculationParameters}
                />
                <Route path="/rechnungspositionen" component={InvoiceItems} />
                <Route path="/benutzer" component={Users} />
                <Route path="/vorlagen" component={Templates} />
                <Route path="/fremdfirmen" component={Contractors} />
              </Switch>
            </AppFrame>
          </AuthenticationContainer>
        </Application>
      </ApolloProvider>
    </Router>
  );
}
